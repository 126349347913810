import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_SPARES_USED } from './useGetSparesUsed';
import { SpareUsedMutationResponse } from './useUpsertSpare';
import { UpdateLogEntrySparesUsedCountCache } from '../fragments.graphql';

export const DELETE_SPARE = gql`
  mutation DeleteSpare($spareId: String!) {
    deleteSpare(spareId: $spareId) {
      spare {
        pkey
        productId
        logKey
      }
    }
  }
`;

interface DeleteSpareResult {
  deleteSpare: (spareId: string) => Promise<SpareUsedMutationResponse>;
  deleteSpareLoading: boolean;
  deleteSpareError?: ApolloError;
}

export const useDeleteSpare = (recordId: string, recordType: RecordType, recordTypeName: string): DeleteSpareResult => {
  const [deleteSpareUsed, { loading, error }] = useMutation(DELETE_SPARE, {
    refetchQueries: [
      GET_SPARES_USED,
    ],
    onError: (error: ApolloError) => {
      logger('DeleteSpare').error(`Error deleting spare for record: ${recordId} -->`, error.message);
    },
    update: (cache, { data }) => {
      if (data?.deleteSpare?.spare) {
        
        const recordCacheId = cache.identify({
          __typename: recordTypeName,
          pkey: recordId,
        });

        let fragmentSelection: DocumentNode | null = null;

        switch (recordType) {
          case RecordType.LogEntry:
            fragmentSelection = UpdateLogEntrySparesUsedCountCache;
            break;
              
          case RecordType.WorkIssue:
          case RecordType.Equipment:
            break;
        
          case RecordType.InventoryGeneral:
          case RecordType.InventoryBeverages:
          case RecordType.InventoryUniform:
          case RecordType.InventoryDryAndColdStore:
          case RecordType.InventoryMedical:
            // Add your logic for handling different Inventory cases
            break;
        
          case RecordType.EquipmentSchedule:
          case RecordType.EventSchedule:
          case RecordType.DrillSchedule:
          case RecordType.InspectionSchedule:
            // Add your logic for handling different schedule-related cases
            break;
        
          case RecordType.PurchaseOrder:
            // Logic for Purchase Order case
            break;
        
          case RecordType.EngineersDayLog:
          case RecordType.RunningLog:
            // Logic for handling Engineer logs
            break;
        
          case RecordType.Crew:
          case RecordType.Guest:
            // Logic for handling Crew and Guest cases
            break;
        
          case RecordType.SmsReport:
          case RecordType.IspsReport:
            // Logic for handling report-related cases
            break;
        
          case RecordType.VesselCertificates:
          case RecordType.CrewCertificates:
            // Logic for handling certificate-related cases
            break;
        
          case RecordType.DocumentationVesselReference:
          case RecordType.DocumentationDrawings:
          case RecordType.DocumentationManuals:
          case RecordType.DocumentationMsds:
          case RecordType.DocumentationPhotos:
          case RecordType.DocumentationSms:
          case RecordType.DocumentationIsps:
            // Logic for handling documentation cases
            break;
        
          case RecordType.HoursOfRest:
            // Logic for handling HoursOfRest case
            break;
        
          case RecordType.Undefined:
            // Logic for handling undefined cases
            break;
        
          default:
            break;
        }

        if (fragmentSelection) {
          const existingRecord = cache.readFragment<any>({
            id: recordCacheId,
            fragment: fragmentSelection,
          });

          if (existingRecord) {
            cache.writeFragment({
              id: recordCacheId,
              fragment: fragmentSelection,
              data: {
                sparesUsedCount: existingRecord.sparesUsedCount - 1,
              },
            });
          } else {
            logger('Cache-DeleteSpare').warning(`${recordType}: ${recordId} not found in cache`);
          }
        } else {
          logger('Cache-DeleteSpare').warning(`Query fragment not valid --> ${fragmentSelection}`);
        }
      } else {
        logger('Cache-DeleteSpare').warning(`${recordType}: ${recordId} cache update paused --> No response from delete mutation`);
      }
    },
  });

  const deleteSpare = async (spareId: string): Promise<SpareUsedMutationResponse> => {
    const response = await deleteSpareUsed({ variables: { spareId } });
    const responseData = response.data?.deleteSpare?.spare;
    if (responseData) {
      logger('DeleteSpare').info(`Spare for ${recordType} deleted successfully.`, response.data);
      return {
        responseData,
        responseMessage: `Spare removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove spare!`,
      };
    };
  };

  return { 
    deleteSpare, 
    deleteSpareLoading: loading, 
    deleteSpareError: error,
  };
};