import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { AttachmentCrossReferenceInput, AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { GET_ATTACHMENTS_FOR_RECORD } from './useGetAttachments';
import { AttachmentMutationResponse } from './useUpsertAttachment';
import { UpdateLogEntryDocumentCountCache, UpdateLogEntryPhotoCountCache } from '../fragments.graphql';

export const DELETE_ATTACHMENT = gql`
  mutation DeleteAttachment($input: AttachmentCrossReferenceInput!) {
    deleteAttachmentFromRecord(input: $input) {
      attachment {
        id
        documentationId
        recordId
        recordType
      }
    }
  }
`;

interface DeleteAttachmentResult {
  deleteAttachment: (input: AttachmentCrossReferenceInput) => Promise<AttachmentMutationResponse>;
  deleteAttachmentLoading: boolean;
  deleteAttachmentError?: ApolloError;
}

export const useDeleteAttachment = (recordId: string, recordType: RecordType, recordTypeName: string, attachmentType: AttachmentType): DeleteAttachmentResult => {
  const [deleteAttachmentFromRecord, { loading }] = useMutation(DELETE_ATTACHMENT, {
    refetchQueries: [
      {
        query: GET_ATTACHMENTS_FOR_RECORD,
        variables: {
          recordId,
          recordType,
          attachmentType
        } 
      }
    ],
    onError: (error: ApolloError) => {
      logger('DeleteAttachment').error(`Error deleting ${attachmentType} for ${recordType}: ${recordId} -->`, error.message);
    },
    update: (cache, { data }) => {
      if (data?.deleteAttachmentFromRecord?.attachment) {
        
        const recordCacheId = cache.identify({
          __typename: recordTypeName,
          pkey: recordId,
        });

        let fragmentSelection: DocumentNode | null = null;

        switch (recordType) {
          case RecordType.LogEntry:
            fragmentSelection = 
              attachmentType === AttachmentType.Document 
              ? UpdateLogEntryDocumentCountCache 
              : UpdateLogEntryPhotoCountCache;
            break;
        
          case RecordType.WorkIssue:
          case RecordType.Equipment:
            break;
        
          case RecordType.InventoryGeneral:
          case RecordType.InventoryBeverages:
          case RecordType.InventoryUniform:
          case RecordType.InventoryDryAndColdStore:
          case RecordType.InventoryMedical:
            // Add your logic for handling different Inventory cases
            break;
        
          case RecordType.EquipmentSchedule:
          case RecordType.EventSchedule:
          case RecordType.DrillSchedule:
          case RecordType.InspectionSchedule:
            // Add your logic for handling different schedule-related cases
            break;
        
          case RecordType.PurchaseOrder:
            // Logic for Purchase Order case
            break;
        
          case RecordType.EngineersDayLog:
          case RecordType.RunningLog:
            // Logic for handling Engineer logs
            break;
        
          case RecordType.Crew:
          case RecordType.Guest:
            // Logic for handling Crew and Guest cases
            break;
        
          case RecordType.SmsReport:
          case RecordType.IspsReport:
            // Logic for handling report-related cases
            break;
        
          case RecordType.VesselCertificates:
          case RecordType.CrewCertificates:
            // Logic for handling certificate-related cases
            break;
        
          case RecordType.DocumentationVesselReference:
          case RecordType.DocumentationDrawings:
          case RecordType.DocumentationManuals:
          case RecordType.DocumentationMsds:
          case RecordType.DocumentationPhotos:
          case RecordType.DocumentationSms:
          case RecordType.DocumentationIsps:
            // Logic for handling documentation cases
            break;
        
          case RecordType.HoursOfRest:
            // Logic for handling HoursOfRest case
            break;
        
          case RecordType.Undefined:
            // Logic for handling undefined cases
            break;
        
          default:
            break;
        }
        

        if (fragmentSelection) {
          const existingRecord = cache.readFragment<any>({
            id: recordCacheId,
            fragment: fragmentSelection,
          });
  
          if (existingRecord) {
            const updatedCount = 
              attachmentType === AttachmentType.Document 
              ? { documentsCount: existingRecord.documentsCount - 1 }
              : { photosCount: existingRecord.photosCount - 1 };
  
            cache.writeFragment({
              id: recordCacheId,
              fragment: fragmentSelection,
              data: updatedCount,
            });
          } else {
            logger('Cache-DeleteAttachment').warning(`${recordType}: ${recordId} - not found in cache`);
          }
        } else {
          logger('Cache-DeleteAttachment').warning(`Query fragment not valid --> ${fragmentSelection}`);
        }
      } else {
        logger('Cache-DeleteAttachment').warning(`${recordType}: ${recordId} cache update failed --> No response from delete mutation`);
      }
    },
  });

  const deleteAttachment = async (input: AttachmentCrossReferenceInput): Promise<AttachmentMutationResponse> => {
    const attachmentTypeDisplay = attachmentType === AttachmentType.Photo ? 'Photo' : 'Document';
    const response = await deleteAttachmentFromRecord({ variables: { input } });
    const responseData = response.data?.deleteAttachmentFromRecord?.attachment;
    if (responseData) {
      logger('DeleteAttachment').info(`${attachmentType} deleted successfully`, response.data);
      return {
        responseData,
        responseMessage: `${attachmentTypeDisplay} removed successfully!`
      };
    } else {
      return {
        responseMessage: `Failed to remove ${attachmentTypeDisplay}!`,
      };
    }
  };

  return { 
    deleteAttachment, 
    deleteAttachmentLoading: loading, 
  };
};
