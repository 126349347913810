import { FC, useState } from 'react';
import '../../../App.css';
import '../../../theme/styles.css';
import '../styles.css';
import { isNil } from 'lodash';
import { CertificateDocument } from 'src/rxdb/collections/Certificates/rxdb';
import { useForm } from 'react-hook-form';
import Input from '../../UI/Forms/Input';
import Tabs from '../../UI/Tabs';
import CertificateSummaryForm from './component/CertificateSummaryForm';
import { useAuth } from '../../../contexts/auth';
import { handleCharLimitWarning, stopPropagate } from 'src/utils';
import { WorkIssuesDocument } from 'src/rxdb/collections/WorkIssues/rxdb';
import { CHAR_LIMIT } from 'src/consts';
import AttachmentTab from 'src/modules/Attachments';
import { AttachmentType, RecordType } from 'src/generated/dotnet.graphql';
import Comments from 'src/modules/Comments';

interface Props {
  certificate: CertificateDocument;
  onSubmit: (data: any) => void;
  isPassport?: boolean; 
  certificateType?: string;
  issue?: WorkIssuesDocument;
}

const CertificateDetailsForm: FC<Props> = ({
  certificate, // original certificate
  onSubmit,
  isPassport = false,
  certificateType,
  issue,
}) => {
  const { user } = useAuth();
  const {
    control, handleSubmit, reset, getValues, setValue, clearErrors,
  } = useForm<any>({
    defaultValues: {
      DateExpire: certificate.DateExpire
        ? new Date(certificate.DateExpire)
        : null,
      DateIssue: certificate.DateIssue
        ? new Date(certificate.DateIssue)
        : null,
      fldCertNum: certificate.fldCertNum || '',
      fldCrewID: certificate.fldCrewID || null,
      fldIssue: certificate.fldIssue || '',
      fldNationality:
          certificate.fldNationality || '',
      fldNotes: certificate.fldNotes || '',
      fldPassport: isPassport ? true : certificate.fldCertType === 1,
      fldResponsible: certificate?.fldResponsible,
      fldSMS: certificate?.fldSMS || false,
      fldType: certificate.fldType || '',
      fldSRHKey: certificate.fldSRHKey || null,
      fldTimeWarn: certificate.fldTimeWarn || null,
      fldTimeWarnMeasure: certificate.fldTimeWarnMeasure || null,
    },
  });
  const { CERTIFICATES } = CHAR_LIMIT;
  
  const [documentsCount, setDocumentsCount] = useState<number>();
  const [photosCount, setPhotosCount] = useState<number>();
  const [commentsCount, setCommentsCount] = useState<number>();

  const onChange = (name: string, value: any) => {
    setValue(name, value);
    clearErrors(name);
  };

  return (
    <form
      id="certificate-details-form"
      className="relative bg-white flex-grow"
      onSubmit={stopPropagate(handleSubmit(onSubmit))}
    >
      <div className="bg-white h-full">
        <div className="px-6 mt-3">
          <div className="mb-6">
            <Input
              inputProps={{
                size: 'medium',
                label: 'Certificate Type',
                variant: 'standard',
              }}
              rules={{ required: true, maxLength: CERTIFICATES.fldType }}
              warning={(value) => handleCharLimitWarning(value, CERTIFICATES.fldType)}
              control={control}
              name="fldType"
              disabled={isPassport}
            />
          </div>

          <Tabs
            tabs={[
              {
                label: 'General Information',
                component: (
                  <CertificateSummaryForm
                    onChange={onChange}
                    control={control}
                    initialValue={certificate}
                    passport={isPassport}
                    certificateType={!isNil(certificateType) ? certificateType: certificate.fldCertType }
                  />
                ),
              },
              {
                label: `Attachments`,
                component: (
                  <AttachmentTab
                    recordId={certificate.PKey}
                    recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates} // ?????
                    recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                    attachmentType={AttachmentType.Document}
                    fldSrhkey={certificate.fldSRHKey}
                    setAttachmentsCount={setDocumentsCount}
                    // readOnly={editFlag || cannotViewFlag} 
                    disableCheckbox={true}
                  />
                ),
              },
              {
                label: `Photos`,
                component: (
                  <AttachmentTab
                    recordId={certificate.PKey}
                    recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates}
                    recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                    attachmentType={AttachmentType.Photo}
                    fldSrhkey={certificate.fldSRHKey}
                    setAttachmentsCount={setPhotosCount}
                    // readOnly={editFlag || cannotViewFlag} 
                    disableCheckbox={certificateType === 'CREWPROFILE'? true: false}
                  />
                ),
              },
              {
                label: `Comments`,
                component: (
                  <Comments
                    recordId={certificate.PKey}
                    recordType={certificateType === 'CREWPROFILE' ? RecordType.CrewCertificates : RecordType.VesselCertificates}
                    recordTypeName={certificateType === 'CREWPROFILE' ? 'CrewCertificates' : 'VesselCertificates'} // when refactor to .net api, add proper object.__typename for counts cache update
                    setCommentsCount={setCommentsCount}
                    // readOnly={editFlag || cannotViewFlag}
                  />
                ),
              },
            ]}
          />
        </div>
      </div>
    </form>
  );
};

export default CertificateDetailsForm;
