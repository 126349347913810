import { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { isEmpty, isNil, isString } from 'lodash';
import {
  IconButton,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Alert,
  Portal,
  Snackbar,
  Autocomplete as MaterialAutocomplete,
  FormGroup,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from 'src/components/UI/Forms/Checkbox';
import { ATTACHMENTS, CHAR_LIMIT } from 'src/consts';
import Input from 'src/components/UI/Forms/Input';
import DatePicker from 'src/components/UI/Forms/DatePicker';
import UploadValidationPopup from './UploadValidationPopup';
import { handleCharLimitWarning } from 'src/utils';
import { isValidDateFormat } from 'src/utils/format-dates';
import {
  Attachment,
  AttachmentInput,
  AttachmentType,
} from 'src/generated/dotnet.graphql';
import FileUploadForm from './FileUploadForm';
import RichTextEditor from 'src/components/UI/Forms/RichTextEditor';
import { ExpandMore } from '@mui/icons-material';

type FilePopupForm = {
  id: string;
  documentationId: string;
  docTitle: any;
  fldRevision: any;
  fldPage: any;
  fldShowInCentral: any;
  fldDescription: any;
  fldNotes: any;
  fldRevisionDate: any;
  fldFileName: any;
  fldLibType: any;
  recordId: any;
  recordType: any;
};

const filedefaultValue = {
  id: '',
  documentationId: '',
  fldFKey: '',
  docTitle: '',
  fldRevision: 0,
  fldPage: 0,
  fldShowInCentral: false,
  fldDescription: '',
  fldNotes: '',
  fldRevisionDate: new Date(),
  fldRecordKey: '',
  fldFileName: '',
  fldLibType: '',
};

const libTypeOptions = [
  {
    id: 0,
    type: 'DRAWINGS',
    label: 'Drawings',
  },
  /* TD-1086 - remove the option to add an attachment to Photos, ISPS or Safety Management System libraries
  {
    id: 1,
    type: 'ISPSDOCS',
    label: 'ISPS',
  },
  {
    id: 2,
    type: 'PHOTOS',
    label: 'Photos',
  },
  {
    id: 3,
    type: 'SMSDOCS',
    label: 'Safety Management System',
  },
  */
  {
    id: 2,
    type: null,
    label: 'Vessel Reference',
  },
];

interface InjectedProps {
  visible: boolean;
  document?: Attachment;
  attachmentType: AttachmentType;
  setUploadedFile: any;
  uploadedFile: any;
  onCancel?: () => void;
  onSubmit: (payload: AttachmentInput) => void;
  saveLoading: boolean;
  maxFiles?: number;
  disableCheckbox?: boolean;
}

const AttachmentPopup: FC<InjectedProps> = ({
  visible,
  document,
  setUploadedFile,
  uploadedFile,
  attachmentType,
  onCancel,
  onSubmit,
  saveLoading,
  maxFiles = 0,
  disableCheckbox = false,
}) => {
  const { control, handleSubmit, reset, getValues, register, setValue } =
    useForm<any>({
      mode: 'onBlur',
      shouldFocusError: true,
    });
  const { ref, ...inputProps } = register('docTitle', {
    required: 'Field required',
  });
  const [libTypeSelected, setLibTypeSelected] = useState<any>(null);
  const [showLibTypeDropdown, setShowLibTypeDropdown] =
    useState<boolean>(false);
  const [isCheckFormVisible, setIsCheckFormVisible] = useState<boolean>(false);
  const [alert, setAlert] = useState(false);
  const { TBLDOCUMENTATION, TBLDOCUMENTREVISIONS } = CHAR_LIMIT;
  const [snackBar, setSnackbar] = useState({
    open: false,
    type: 'success',
    message: '',
  });

  const onSnackbarClose = () => {
    setSnackbar({
      open: false,
      message: '',
      type: 'success',
    });
  };

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(document)) return reset(filedefaultValue);

    if (document.fldShowInCentral) {
      setShowLibTypeDropdown(true);
    }
    if (document.fldLibType === 'SMSDOCS') {
      setLibTypeSelected('Safety Management System');
    }
    if (document.fldLibType === 'PHOTOS') {
      setLibTypeSelected('Photos');
    }
    if (document.fldLibType === 'DRAWINGS') {
      setLibTypeSelected('Drawings');
    }
    if (document.fldLibType === null) {
      setLibTypeSelected('Vessel Reference');
    }

    const payload = {
      id: document.id,
      documentationId: document.documentationId,
      docTitle: document.docTitle,
      fldRevision: document.fldRevision,
      fldPage: document.fldPage,
      fldShowInCentral: document.fldShowInCentral,
      fldDescription: document.fldDescription,
      fldNotes: document.fldNotes,
      fldRevisionDate: document.fldRevisionDate
        ? new Date(document.fldRevisionDate)
        : null,
      fldFileName: document.fldFileName,
      fldLibType: document.fldLibType,
      recordId: document.recordId,
      recordType: document.recordType,
    };

    updateFormData(payload);
  };

  useEffect(() => {
    setInitialValue();
  }, [document]);

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const onChange = async (name: string, value: any) => {
    let shouldDirty = true;

    setValue(name, value, { shouldDirty: shouldDirty });
  };

  const updateFormAfterFileUpload = async () => {
    setValue(
      'docTitle',
      isNil(document?.docTitle)
        ? isEmpty(getValues().docTitle)
          ? uploadedFile.name
          : getValues().docTitle
        : document?.docTitle
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!isEmpty(uploadedFile.name)) {
        updateFormAfterFileUpload();
      }
    };

    fetchData();
  }, [uploadedFile]);

  const handleChangeLibDropdown = (evt: any) => {
    setShowLibTypeDropdown(evt.target.checked);
  };

  const onSaveClicked = async (data: FilePopupForm) => {
    if (!uploadedFile.url && document && !document.fldFileName) {
      setAlert(true);
      return;
    }
    //Note: Add below code for check file is uploaded or not for add new
    if (isNil(document)) {
      if (uploadedFile.url === '') {
        setAlert(true);
        return;
      }
    }

    const libType =
      attachmentType === AttachmentType.Photo
        ? 'PHOTOS'
        : isString(data.fldLibType)
        ? data.fldLibType
        : !isNil(data.fldLibType)
        ? data.fldLibType.type
        : null;

    const payload: AttachmentInput = {
      id: data.id || null,
      documentationId: data.documentationId || null,
      fldFileName: data.fldFileName,
      docTitle: data.docTitle,
      fldRevision: data.fldRevision || null,
      fldPage: data.fldPage || null,
      fldShowInCentral: data.fldShowInCentral,
      fldDescription: data.fldDescription,
      fldNotes: data.fldNotes,
      fldRevisionDate: data.fldRevisionDate,
      fldLibType: libType,
      recordId: data.recordId || null,
      recordType: data.recordType || null,
    };

    onSubmit(payload);
    setLibTypeSelected(undefined);
    setAlert(false);
    setShowLibTypeDropdown(false);
    reset(filedefaultValue);
  };

  const handleChange = (newData: any) => {
    setLibTypeSelected(newData);
    setValue('fldLibType', newData);
  };

  const onCancelClick = () => {
    reset(filedefaultValue);
    setLibTypeSelected(undefined);
    setShowLibTypeDropdown(false);
    setAlert(false);
    onCancel && onCancel();
  };

  const handleOnCancel = () => {
    setIsCheckFormVisible(false);
  };

  const handleOnUpload = async () => {
    setIsCheckFormVisible(false);
  };

  const handleOnLink = async () => {
    if (document) {
      console.log('handleOnLink()');
      // const db = await getDatabase();
      // const documentCrossResponse = await db.tbldocumentcrossreference.upsert({
      //     PKey: uuid(),
      //     fldFKey: document.PKey,
      //     fldRecordKey: recordKey,
      //     fldTableName: tableName,
      // });

      // let schedMaint: any = await db.collections.tblschedmaint.findOne({
      //   selector: {
      //     PKey: initialValue.SchedKey,
      //   }
      // }).exec();
      // let fldHTML: string = "OPEN ATTACHED PROCEDURE FOR INSTRUCTIONS. " + (schedMaint.fldHTML ?? "");
      // await schedMaint.atomicPatch({
      //     fldLinkID: documentCrossResponse.fldFKey,
      //     fldHTML: fldHTML,
      // });
      // initialValue.fldLinkID = documentCrossResponse.fldFKey;
      // initialValue.fldHTML = fldHTML;
    }
    handleOnCancel();
    onCancelClick();
  };

  const showDropdown = attachmentType === AttachmentType.Document && showLibTypeDropdown;

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '16px' }}>
            <span className="ml-2">
              {attachmentType === AttachmentType.Photo
                ? ATTACHMENTS.ATTACH_PHOTOS
                : ATTACHMENTS.ATTACH_FILES}
            </span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancelClick}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            {alert && (
              <Alert severity="error" className="mb-5">
                File is empty
              </Alert>
            )}
            <form
              id="Attachment-popup"
              className="relative bg-white flex-grow"
              onSubmit={handleSubmit(onSaveClicked)}
            >
              <div className="bg-white h-full flex-grow">
                <div className="h-full">
                  <div className="mb-6">
                    <div className="mb-3">
                      <Input
                        inputProps={{
                          size: 'small',
                          label: 'Document Title',
                        }}
                        rules={{
                          required: 'Document Title Required!',
                          maxLength: TBLDOCUMENTATION.DocTitle,
                        }}
                        warning={(value) =>
                          handleCharLimitWarning(
                            value,
                            TBLDOCUMENTATION.DocTitle
                          )
                        }
                        control={control}
                        name="docTitle"
                      />
                    </div>
                    
                    {!disableCheckbox && (
                      <div>
                        <Checkbox
                          control={control}
                          name="fldShowInCentral"
                          label="Include in Documentation Libraries"
                          onChange={handleChangeLibDropdown}
                        />
                      </div>
                    )}

                    {showDropdown && (
                      <>
                        <Accordion 
                          defaultExpanded 
                          disableGutters
                          className='mt-2'
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="general-information"
                            id="general-information"
                          >
                            General Information
                          </AccordionSummary>
                          <AccordionDetails>
                            <div>
                              <MaterialAutocomplete
                                disablePortal
                                id="File-type"
                                value={libTypeSelected}
                                options={libTypeOptions}
                                getOptionLabel={(option: any) => {
                                  if (typeof option === 'string') {
                                    return option;
                                  }
                                  return option.label;
                                }}
                                // renderOption={(props, option: any) => <li {...props}>{option}</li>}
                                onChange={(event, newValue: any) => {
                                  handleChange(newValue);
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label="Library Type"
                                    autoComplete="off"
                                    size="small"
                                  />
                                )}
                              />
                            </div>
                            <div className="mt-3">
                              <RichTextEditor
                                control={control}
                                name="fldDescription"
                                onChange={onChange}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                        
                        <Accordion 
                          defaultExpanded 
                          disableGutters 
                          className='mt-2'
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMore />}
                            aria-controls="revision-information"
                            id="revision-information"
                          >
                            Revision Information
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup row>
                            <div className="mr-5">
                                <DatePicker
                                  name="fldRevisionDate"
                                  control={control}
                                  label="Revision Date"
                                  rules={{
                                    validate: (value) =>
                                      isValidDateFormat(value) ||
                                      'Please enter a valid date format dd-MMM-yyyy',
                                  }}
                                />
                              </div>
                              <div className="mr-5">
                                <Input
                                  inputProps={{
                                    size: 'small',
                                    label: 'Revision #',
                                    style: { textAlign: 'end' },
                                  }}
                                  rules={{
                                    maxLength: TBLDOCUMENTREVISIONS.fldRevision,
                                  }}
                                  warning={(value) =>
                                    handleCharLimitWarning(
                                      value,
                                      TBLDOCUMENTREVISIONS.fldRevision
                                    )
                                  }
                                  control={control}
                                  name="fldRevision"
                                />
                              </div>
                              <div>
                                <Input
                                  inputProps={{
                                    size: 'small',
                                    label: 'Page #',
                                    style: { textAlign: 'end' },
                                  }}
                                  rules={{ maxLength: TBLDOCUMENTATION.fldPage }}
                                  warning={(value) =>
                                    handleCharLimitWarning(
                                      value,
                                      TBLDOCUMENTATION.fldPage
                                    )
                                  }
                                  control={control}
                                  name="fldPage"
                                />
                              </div>
                            </FormGroup>

                            <div className="mt-3">
                              <Input
                                inputProps={{
                                  size: 'small',
                                  label: 'Revision Notes',
                                }}
                                control={control}
                                name="fldNotes"
                                rows={2}
                                multiline={true}
                              />
                            </div>
                          </AccordionDetails>
                        </Accordion>
                      </>
                    )}
                  </div>
                </div>
              </div>
              <UploadValidationPopup
                visible={isCheckFormVisible}
                onCancel={handleOnCancel}
                onLink={handleOnLink}
                onUpload={handleOnUpload}
              />
            </form>
            <FileUploadForm
              setUploadedFile={setUploadedFile}
              uploadedFile={uploadedFile}
              attachmentType={attachmentType}
              document={document}
              maxFiles={maxFiles}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <LoadingButton
              onClick={handleSubmit(onSaveClicked)}
              loading={saveLoading}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </LoadingButton>
            <Button onClick={onCancelClick} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Portal>
          <Snackbar
            open={snackBar.open}
            autoHideDuration={2000}
            onClose={onSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          >
            <Alert severity={snackBar.type as any} sx={{ width: '100%' }}>
              {snackBar.message}
            </Alert>
          </Snackbar>
        </Portal>
      </form>
    </div>
  );
};

export default AttachmentPopup;
