import { FC, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { isEmpty, isNil, size } from 'lodash';
import Button from '@mui/material/Button';
import { IconButton, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Add, Edit } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { Inventory, TblSparesUsed } from 'src/generated/dotnet.graphql';
import InventoryPopup from './components/InventoryPopup';
import InventoryForEquipmentPopup from './components/InventoryForEquipmentPopup';
import { useGetInventoryForEquipment } from 'src/hooks/inventory/useGetInventoryForEquipment';
import { INVENTORY } from 'src/consts';

export interface ISparePopupForm {
  pkey: string;
  productName: string | null;
  productId: any;
  manufacturer: any;
  fldPartNumber: any;
  amount: any;
};

const sparedefaultValue = {
  pkey: '',
  productName: null,
  productId: '',
  manufacturer: '',
  fldPartNumber: '',
  amount: 0,
};

interface InjectedProps {
  spareUsed?: TblSparesUsed;
  eqKey?: string; 
  visible: boolean;
  onCancel?: () => void;
  onSubmit: (payload: ISparePopupForm) => void;
  issue: any; // when you get to refactor Tasks module with the dotnet API, send only the eqKey related to the issue
}

const SparePopup: FC<InjectedProps> = ({
  spareUsed,
  eqKey,
  visible,
  onCancel,
  onSubmit,
  issue,
}) => {
  const {
    control, handleSubmit, reset, getValues,
  } = useForm<ISparePopupForm>({
    mode: 'onBlur',
  });
  const [isVisibileInventoryPopup, setIsVisibileInventoryPopup] = useState(false);
  const [isVisibileInventoryForEquipmentPopup, setIsVisibileInventoryForEquipmentPopup] = useState(false);
  const { inventoryForEquipment, inventoryForEquipmentLoading, inventoryForEquipmentCount } = useGetInventoryForEquipment(eqKey)

  const updateFormData = (payload: any) => {
    if (!isNil(payload)) {
      reset(payload);
    }
  };

  const setInitialValue = async () => {
    // Dont do anything special
    if (isNil(spareUsed)) return reset(sparedefaultValue);

    const payload = {
      pkey: spareUsed.pkey,
      productId: spareUsed.productId,
      fldPartNumber: spareUsed.inventory.partNumber,
      amount: spareUsed.amount,
      productName: spareUsed.inventory.productName,
      manufacturer: spareUsed.inventory.manufacturer,
    };

    updateFormData(payload);
  };

  const onSpareAddClick = () => {
    if (inventoryForEquipmentCount > 0) {
      setIsVisibileInventoryForEquipmentPopup(true);
    } else {
      setIsVisibileInventoryPopup(true);
    }
  };

  useEffect(() => {
    if (isNil(spareUsed) && visible) {
      onSpareAddClick();
    }
  }, [visible]);

  useEffect(() => {
    setInitialValue();
  }, [spareUsed]);

  const handleSearchMore = () => {
    setIsVisibileInventoryPopup(true);
    setIsVisibileInventoryForEquipmentPopup(false);
  };

  const onCancelInventoryPopup = () => {
    reset(sparedefaultValue);
    setIsVisibileInventoryPopup(false);
    setIsVisibileInventoryForEquipmentPopup(false);
    onCancel && onCancel();
  };

  const onSelectionChange = (inventory: Inventory) => {
    if (isNil(inventory)) return;
    const payload = {
      pkey: null,
      productId: inventory.id,
      fldPartNumber: inventory.partNumber,
      manufacturer: inventory.manufacturer,
      productName: inventory.productName,
      amount: 0,
    };
    updateFormData(payload);
  };

  const onSelect = (inventory: Inventory) => {
    onSelectionChange(inventory);
    setIsVisibileInventoryForEquipmentPopup(false);
    setIsVisibileInventoryPopup(false);
  };

  const onSaveClicked = (payload: ISparePopupForm) => {
    onSubmit(payload);
  };

  return (
    <div>
      <form>
        <Dialog
          open={visible}
          onClose={onCancel}
          fullWidth
          maxWidth="md"
          scroll="paper"
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle sx={{ m: 0, p: 4 }} style={{ fontSize: '26px' }}>
            <span className="ml-2">Spare Info</span>
            {onCancel ? (
              <IconButton
                aria-label="close"
                onClick={onCancel}
                sx={{
                  position: 'absolute',
                  right: 10,
                  top: 14,
                  color: (theme) => theme.palette.grey[400],
                }}
              >
                <CloseIcon />
              </IconButton>
            ) : null}
          </DialogTitle>
          <DialogContent dividers style={{ maxHeight: '60vh' }}>
            <Controller
              name="productName"
              control={control}
              defaultValue=""
              rules={{ required: 'Product name required' }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  required
                  value={value || ''}
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  label="Product Name"
                  className="mb-8 w-full"
                  autoComplete='off'
                  size="small"
                  InputProps={{
                    readOnly: true,
                    endAdornment: !spareUsed?.productId && (
                      <IconButton onClick={onSpareAddClick}>
                        {isEmpty(getValues().productName) ?
                          <Add /> :
                          <Edit />
                        }
                      </IconButton>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="manufacturer"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={value}
                  autoComplete='off'
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Manufacturer"
                  size="small"
                />
              )}
            />
            <Controller
              name="fldPartNumber"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => (
                <TextField
                  InputProps={{
                    readOnly: true,
                  }}
                  value={value}
                  autoComplete='off'
                  onChange={onChange}
                  className="mb-8 w-full"
                  label="Part Number"
                  size="small"
                />
              )}
            />
            <Controller
              name="amount"
              control={control}
              defaultValue={0}
              rules={{
                validate: () => {
                  if (!(getValues('amount') > 0 && getValues('amount') < 501)) {
                    return 'Amout should between 1 to 500';
                  }
                },
              }}
              render={({
                field: { onChange, value },
                fieldState: { error },
              }) => (
                <TextField
                  label="Amount"
                  type="number"
                  autoComplete='off'
                  error={!!error}
                  helperText={error ? error.message : null}
                  className="mb-8 w-full"
                  value={value}
                  onChange={onChange}
                  size="small"
                  inputProps={{
                    max: 500,
                    min: 0,
                  }}
                />
              )}
            />
          </DialogContent>
          <DialogActions sx={{ m: 0, p: 3 }}>
            <Button
              onClick={handleSubmit(onSaveClicked)}
              className="ml-4 mr-3 w-32"
              variant="contained"
            >
              Save
            </Button>
            <Button onClick={onCancel} className="w-32">
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </form>

      <div>
        <InventoryForEquipmentPopup
          isVisible={isVisibileInventoryForEquipmentPopup}
          inventoryForEquipment={inventoryForEquipment}
          onCancelInventoryPopup={onCancelInventoryPopup}
          onSelect={onSelect}
          handleSearchMore={handleSearchMore}
        />
      </div>

      <div>
        <InventoryPopup
          isVisibile={isVisibileInventoryPopup}
          onCancelInventoryPopup={onCancelInventoryPopup}
          onSelect={onSelect}
          inventoryType={INVENTORY.GENERAL.dataType}
        />
      </div>
      
    </div>
  );
};

export default SparePopup;
