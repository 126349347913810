import { ApolloError, DocumentNode, gql, useMutation } from '@apollo/client';
import { RecordType } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { CommentMutationResponse } from './useAddCommentsForRecord';
import { UpdateLogEntryCommentsCountCache } from '../fragments.graphql';

export const DELETE_COMMENT = gql`
  mutation DeleteComment($commentId: String!) {
    deleteComment(commentId: $commentId) {
      comment {
        id
        deletedAt
        deletedBy
        replies {
          id
          deletedAt
          deletedBy
        }
      }
    }
  }
`;

interface DeleteCommentResult {
  deleteComment: (commentId: string) => Promise<CommentMutationResponse>;
  deleteCommentLoading: boolean;
}

export const useDeleteComment = (recordId: string, recordType: RecordType, recordTypeName: string, isReply: boolean): DeleteCommentResult => {
  const [removeComment, { loading }] = useMutation(DELETE_COMMENT, {
    onError: (error: ApolloError) => {
      logger('DeleteComment').error(`Error deleting comment for ${recordType}: ${recordId} -->`, error.message);
    },
    update: (cache, { data }) => {
      if (!isReply) {
        if (data?.deleteComment?.comment) {
          // TODO - refactor to use recordType so dinamically updates cache for different tables
          const recordCacheId = cache.identify({
            __typename: recordTypeName,
            pkey: recordId,
          });

          let fragmentSelection: DocumentNode | null = null;

          switch (recordType) {
            case RecordType.LogEntry:
              fragmentSelection = UpdateLogEntryCommentsCountCache;
              break;
              
            case RecordType.WorkIssue:
            case RecordType.Equipment:
              break;
          
            case RecordType.InventoryGeneral:
            case RecordType.InventoryBeverages:
            case RecordType.InventoryUniform:
            case RecordType.InventoryDryAndColdStore:
            case RecordType.InventoryMedical:
              // Add your logic for handling different Inventory cases
              break;
          
            case RecordType.EquipmentSchedule:
            case RecordType.EventSchedule:
            case RecordType.DrillSchedule:
            case RecordType.InspectionSchedule:
              // Add your logic for handling different schedule-related cases
              break;
          
            case RecordType.PurchaseOrder:
              // Logic for Purchase Order case
              break;
          
            case RecordType.EngineersDayLog:
            case RecordType.RunningLog:
              // Logic for handling Engineer logs
              break;
          
            case RecordType.Crew:
            case RecordType.Guest:
              // Logic for handling Crew and Guest cases
              break;
          
            case RecordType.SmsReport:
            case RecordType.IspsReport:
              // Logic for handling report-related cases
              break;
          
            case RecordType.VesselCertificates:
            case RecordType.CrewCertificates:
              // Logic for handling certificate-related cases
              break;
          
            case RecordType.DocumentationVesselReference:
            case RecordType.DocumentationDrawings:
            case RecordType.DocumentationManuals:
            case RecordType.DocumentationMsds:
            case RecordType.DocumentationPhotos:
            case RecordType.DocumentationSms:
            case RecordType.DocumentationIsps:
              // Logic for handling documentation cases
              break;
          
            case RecordType.HoursOfRest:
              // Logic for handling HoursOfRest case
              break;
          
            case RecordType.Undefined:
              // Logic for handling undefined cases
              break;
          
            default:
              break;
          }

          if (fragmentSelection) {
            const existingRecord = cache.readFragment<any>({
              id: recordCacheId,
              fragment: fragmentSelection,
            });
    
            if (existingRecord && existingRecord.commentsCount > 0) {
              cache.writeFragment({
                id: recordCacheId,
                fragment: fragmentSelection,
                data: {
                  commentsCount: existingRecord.commentsCount - 1,
                },
              });
            } else {
              logger('Cache-DeleteComment').warning(`${recordType}: ${recordId} not found in cache`);
            }
          } else {
            logger('Cache-DeleteComment').warning(`Query fragment not valid --> ${fragmentSelection}`);
          }
        } else {
          logger('Cache-DeleteComment').warning(`${recordType}: ${recordId} cache update paused --> No response from delete mutation`);
        }
      }
    },
  });

  const deleteComment = async (commentId: string): Promise<CommentMutationResponse> => {
    const response = await removeComment({ variables: { commentId } });
    const responseData = response.data?.deleteComment?.comment;
    if (responseData) {
      logger('DeleteComment').info('Comment deleted successfully', response.data);
      return {
        responseData,
        responseMessage: `${isReply ? 'Reply' : 'Comment'} removed successfully!`,
      };
    } else {
      return {
        responseMessage: `Failed to remove ${isReply ? 'reply' : 'comment'}!`,
      };
    }
  };

  return { 
    deleteComment, 
    deleteCommentLoading: loading, 
  };
};
