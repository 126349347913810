import { gql, LazyQueryExecFunction, OperationVariables, useLazyQuery } from "@apollo/client";
import { LogEntry } from "src/generated/dotnet.graphql";
import { logger } from "src/helpers/logger";

export const GET_LOG_ENTRY = gql`
  query GetLogEntry($pKey: String!) {
    logEntry(pKey: $pKey) {
      __typename
      pkey
      logDate
      maintKey
      eqKey
      fldCost
      curr
      fldCompany
      fldTime
      fldTimeQty
      fldHours
      fldPerformedBy
      fldEnteredBy
      fldTsachk
      department
      fldCrewId
      fldRestricted
      fldIsWarranty
      fldLocHierarchy
      dateEntered
      fldIsCheckList
      fldWorkList
      fldHtml
      fldSrhkey
      fldSms
      checks
      year
      month
      equipment {
        __typename
        id
        countHours
        hoursOffset
        hours
        uniqueName
      }
      sparesUsedCount
      photosCount
      documentsCount
      commentsCount
    }
  }
`

export interface LogEntryData {
  logEntry: LogEntry;
};

interface LogEntryResult {
  getLazyLogEntry: LazyQueryExecFunction<LogEntryData, OperationVariables>;
}

export const useGetLazyLogEntry = (): LogEntryResult => {

  const [ getLogEntry, { error }] = useLazyQuery(GET_LOG_ENTRY, {
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });
  
  if (error) {
    logger('GetLogEntry').error('Error fetching log entry -->', error.message);
  }

  return {
    getLazyLogEntry: getLogEntry
  };
};
  