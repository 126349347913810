export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The `Byte` scalar type represents non-fractional whole numeric values. Byte can represent values between 0 and 255. */
  Byte: any;
  /** The `DateTime` scalar represents an ISO-8601 compliant date time type. */
  DateTime: any;
  /** The `Decimal` scalar type represents a decimal floating-point number. */
  Decimal: any;
};



export type AddCommentInput = {
  content: Scalars['String'];
  recordId: Scalars['String'];
  recordType: RecordType;
  parentId?: Maybe<Scalars['String']>;
};

/** Defines when a policy shall be executed. */
export enum ApplyPolicy {
  /** Before the resolver was executed. */
  BeforeResolver = 'BEFORE_RESOLVER',
  /** After the resolver was executed. */
  AfterResolver = 'AFTER_RESOLVER',
  /** The policy is applied in the validation step before the execution. */
  Validation = 'VALIDATION'
}

export type Attachment = {
  __typename?: 'Attachment';
  documentationId?: Maybe<Scalars['String']>;
  docTitle: Scalars['String'];
  fldDescription?: Maybe<Scalars['String']>;
  fldShowInCentral?: Maybe<Scalars['Boolean']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLibType?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  fldRevision?: Maybe<Scalars['String']>;
  fldRevisionDate?: Maybe<Scalars['DateTime']>;
  fldAltPath?: Maybe<Scalars['Int']>;
  fldFileName?: Maybe<Scalars['String']>;
  fldNotes?: Maybe<Scalars['String']>;
  fldPage?: Maybe<Scalars['String']>;
  fileExtension?: Maybe<Scalars['String']>;
  attachmentType: AttachmentType;
  id?: Maybe<Scalars['String']>;
  recordId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
};

export type AttachmentCrossReferenceInput = {
  recordId: Scalars['String'];
  documentationId: Scalars['String'];
};

export type AttachmentInput = {
  id?: Maybe<Scalars['String']>;
  documentationId?: Maybe<Scalars['String']>;
  docTitle?: Maybe<Scalars['String']>;
  fldDescription?: Maybe<Scalars['String']>;
  fldShowInCentral?: Maybe<Scalars['Boolean']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLibType?: Maybe<Scalars['String']>;
  fldRevision?: Maybe<Scalars['String']>;
  fldRevisionDate?: Maybe<Scalars['DateTime']>;
  fldAltPath?: Maybe<Scalars['Int']>;
  fldFileName?: Maybe<Scalars['String']>;
  fldNotes?: Maybe<Scalars['String']>;
  fldPage?: Maybe<Scalars['String']>;
  recordId: Scalars['String'];
  recordType: RecordType;
};

export type AttachmentPayload = {
  __typename?: 'AttachmentPayload';
  attachment?: Maybe<Attachment>;
  errors?: Maybe<Array<UserError>>;
};

export type AttachmentSortInput = {
  documentationId?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  fldDescription?: Maybe<SortEnumType>;
  fldShowInCentral?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  fldLibType?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  fldRevision?: Maybe<SortEnumType>;
  fldRevisionDate?: Maybe<SortEnumType>;
  fldAltPath?: Maybe<SortEnumType>;
  fldFileName?: Maybe<SortEnumType>;
  fldNotes?: Maybe<SortEnumType>;
  fldPage?: Maybe<SortEnumType>;
  fileExtension?: Maybe<SortEnumType>;
  attachmentType?: Maybe<SortEnumType>;
  id?: Maybe<SortEnumType>;
  recordId?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
};

export enum AttachmentType {
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
  Unknown = 'UNKNOWN'
}


export type CategorySortInput = {
  pkey?: Maybe<SortEnumType>;
  fldCategory?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  fldTags?: Maybe<SortEnumType>;
  fldDepts?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

/** Information about the offset pagination. */
export type CollectionSegmentInfo = {
  __typename?: 'CollectionSegmentInfo';
  /** Indicates whether more items exist following the set defined by the clients arguments. */
  hasNextPage: Scalars['Boolean'];
  /** Indicates whether more items exist prior the set defined by the clients arguments. */
  hasPreviousPage: Scalars['Boolean'];
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['String'];
  content?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  authorId: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  parent?: Maybe<Comment>;
  recordId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  reportId?: Maybe<Scalars['String']>;
  recordType?: Maybe<Scalars['String']>;
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  author?: Maybe<CrewProfile>;
  replies: Array<Comment>;
};

export type CommentPayload = {
  __typename?: 'CommentPayload';
  comment?: Maybe<Comment>;
  errors?: Maybe<Array<UserError>>;
};

export type CommentSortInput = {
  id?: Maybe<SortEnumType>;
  content?: Maybe<SortEnumType>;
  createdAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  authorId?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  parent?: Maybe<CommentSortInput>;
  recordId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  reportId?: Maybe<SortEnumType>;
  recordType?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
  author?: Maybe<CrewProfileSortInput>;
};

export type Company = {
  __typename?: 'Company';
  name?: Maybe<Scalars['String']>;
  module?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
};

export type CompanyFilterInput = {
  and?: Maybe<Array<CompanyFilterInput>>;
  or?: Maybe<Array<CompanyFilterInput>>;
  name?: Maybe<StringOperationFilterInput>;
  module?: Maybe<StringOperationFilterInput>;
  department?: Maybe<StringOperationFilterInput>;
};

export type CompanyName = {
  __typename?: 'CompanyName';
  name?: Maybe<Scalars['String']>;
};

export type CompanySortInput = {
  name?: Maybe<SortEnumType>;
  module?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
};

export type Crew = {
  __typename?: 'Crew';
  fldCrewId: Scalars['String'];
  fldFirst?: Maybe<Scalars['String']>;
  fldLast?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
};

export type CrewProfile = {
  __typename?: 'CrewProfile';
  fldCrewId: Scalars['String'];
  fldCurrent?: Maybe<Scalars['Boolean']>;
  crewPassword?: Maybe<Scalars['String']>;
  noView?: Maybe<Scalars['Boolean']>;
  fldFirst?: Maybe<Scalars['String']>;
  fldMiddle?: Maybe<Scalars['String']>;
  fldLast?: Maybe<Scalars['String']>;
  fldDob?: Maybe<Scalars['DateTime']>;
  fldPosition?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldAddress?: Maybe<Scalars['String']>;
  fldPassport?: Maybe<Scalars['String']>;
  fldNationality?: Maybe<Scalars['String']>;
  fldIssue?: Maybe<Scalars['String']>;
  dateIssue?: Maybe<Scalars['DateTime']>;
  dateExpire?: Maybe<Scalars['DateTime']>;
  fldStationBill?: Maybe<Scalars['String']>;
  fldPicture?: Maybe<Scalars['String']>;
  fldDl?: Maybe<Scalars['String']>;
  fldBupa?: Maybe<Scalars['String']>;
  fldReligion?: Maybe<Scalars['String']>;
  fldFood?: Maybe<Scalars['String']>;
  fldPersonalNotes?: Maybe<Scalars['String']>;
  fldHeight?: Maybe<Scalars['Decimal']>;
  fldWeight?: Maybe<Scalars['Decimal']>;
  fldShoeSize?: Maybe<Scalars['Decimal']>;
  fldChest?: Maybe<Scalars['Decimal']>;
  fldNeck?: Maybe<Scalars['Decimal']>;
  fldWaist?: Maybe<Scalars['Decimal']>;
  fldInseam?: Maybe<Scalars['Decimal']>;
  fldHeightMeas?: Maybe<Scalars['String']>;
  fldWeightMeas?: Maybe<Scalars['String']>;
  fldShoeMeas?: Maybe<Scalars['String']>;
  fldChestMeas?: Maybe<Scalars['String']>;
  fldNeckMeas?: Maybe<Scalars['String']>;
  fldWaistMeas?: Maybe<Scalars['String']>;
  fldInseamMeas?: Maybe<Scalars['String']>;
  fldLaundryNum?: Maybe<Scalars['Int']>;
  fldAllergies?: Maybe<Scalars['String']>;
  fldMeds?: Maybe<Scalars['String']>;
  fldMedInstruct?: Maybe<Scalars['String']>;
  fldDiet?: Maybe<Scalars['String']>;
  fldMedNotes?: Maybe<Scalars['String']>;
  fldNok1?: Maybe<Scalars['String']>;
  fldPhone1?: Maybe<Scalars['String']>;
  fldFax1?: Maybe<Scalars['String']>;
  fldNok1mobile?: Maybe<Scalars['String']>;
  fldNok1email?: Maybe<Scalars['String']>;
  fldNok2?: Maybe<Scalars['String']>;
  fldPhone2?: Maybe<Scalars['String']>;
  fldFax2?: Maybe<Scalars['String']>;
  fldNok2mobile?: Maybe<Scalars['String']>;
  fldNok2email?: Maybe<Scalars['String']>;
  dateHire?: Maybe<Scalars['DateTime']>;
  fldSalary?: Maybe<Scalars['Decimal']>;
  fldCurrentPay?: Maybe<Scalars['Decimal']>;
  dateFire?: Maybe<Scalars['DateTime']>;
  fldBank?: Maybe<Scalars['String']>;
  fldAcct?: Maybe<Scalars['String']>;
  fldWire?: Maybe<Scalars['String']>;
  datePayRev?: Maybe<Scalars['DateTime']>;
  fldVacDays?: Maybe<Scalars['Decimal']>;
  fldVacMeas?: Maybe<Scalars['String']>;
  dateVac?: Maybe<Scalars['DateTime']>;
  fldRemarks?: Maybe<Scalars['String']>;
  fldNameupper?: Maybe<Scalars['String']>;
  fldIsGuest?: Maybe<Scalars['Boolean']>;
  fldMarital?: Maybe<Scalars['String']>;
  fldPlaceOfBirth?: Maybe<Scalars['String']>;
  fldBankPhone?: Maybe<Scalars['String']>;
  fldBankFax?: Maybe<Scalars['String']>;
  fldBankEmail?: Maybe<Scalars['String']>;
  fldBankContact?: Maybe<Scalars['String']>;
  fldBankRouting?: Maybe<Scalars['String']>;
  fldBankSwift?: Maybe<Scalars['String']>;
  fldBankIban?: Maybe<Scalars['String']>;
  fldBankCurr?: Maybe<Scalars['String']>;
  departmentHead?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldIsSo?: Maybe<Scalars['Boolean']>;
  fldIsSso?: Maybe<Scalars['Boolean']>;
  fldIsWatchkeeper?: Maybe<Scalars['Boolean']>;
  fldMedConsent?: Maybe<Scalars['Boolean']>;
  fldBloodAbo?: Maybe<Scalars['String']>;
  fldBloodRh?: Maybe<Scalars['String']>;
  fldSeaDis?: Maybe<Scalars['Boolean']>;
  fldCertsOnBoard?: Maybe<Scalars['Boolean']>;
  fldCrewFamiliar?: Maybe<Scalars['DateTime']>;
  fldIsAdmin?: Maybe<Scalars['Boolean']>;
  fldDefaultPassport?: Maybe<Scalars['String']>;
  crewUsername?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  comments: Array<Comment>;
};

export type CrewProfileSortInput = {
  fldCrewId?: Maybe<SortEnumType>;
  fldCurrent?: Maybe<SortEnumType>;
  crewPassword?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  fldFirst?: Maybe<SortEnumType>;
  fldMiddle?: Maybe<SortEnumType>;
  fldLast?: Maybe<SortEnumType>;
  fldDob?: Maybe<SortEnumType>;
  fldPosition?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  fldAddress?: Maybe<SortEnumType>;
  fldPassport?: Maybe<SortEnumType>;
  fldNationality?: Maybe<SortEnumType>;
  fldIssue?: Maybe<SortEnumType>;
  dateIssue?: Maybe<SortEnumType>;
  dateExpire?: Maybe<SortEnumType>;
  fldStationBill?: Maybe<SortEnumType>;
  fldPicture?: Maybe<SortEnumType>;
  fldDl?: Maybe<SortEnumType>;
  fldBupa?: Maybe<SortEnumType>;
  fldReligion?: Maybe<SortEnumType>;
  fldFood?: Maybe<SortEnumType>;
  fldPersonalNotes?: Maybe<SortEnumType>;
  fldHeight?: Maybe<SortEnumType>;
  fldWeight?: Maybe<SortEnumType>;
  fldShoeSize?: Maybe<SortEnumType>;
  fldChest?: Maybe<SortEnumType>;
  fldNeck?: Maybe<SortEnumType>;
  fldWaist?: Maybe<SortEnumType>;
  fldInseam?: Maybe<SortEnumType>;
  fldHeightMeas?: Maybe<SortEnumType>;
  fldWeightMeas?: Maybe<SortEnumType>;
  fldShoeMeas?: Maybe<SortEnumType>;
  fldChestMeas?: Maybe<SortEnumType>;
  fldNeckMeas?: Maybe<SortEnumType>;
  fldWaistMeas?: Maybe<SortEnumType>;
  fldInseamMeas?: Maybe<SortEnumType>;
  fldLaundryNum?: Maybe<SortEnumType>;
  fldAllergies?: Maybe<SortEnumType>;
  fldMeds?: Maybe<SortEnumType>;
  fldMedInstruct?: Maybe<SortEnumType>;
  fldDiet?: Maybe<SortEnumType>;
  fldMedNotes?: Maybe<SortEnumType>;
  fldNok1?: Maybe<SortEnumType>;
  fldPhone1?: Maybe<SortEnumType>;
  fldFax1?: Maybe<SortEnumType>;
  fldNok1mobile?: Maybe<SortEnumType>;
  fldNok1email?: Maybe<SortEnumType>;
  fldNok2?: Maybe<SortEnumType>;
  fldPhone2?: Maybe<SortEnumType>;
  fldFax2?: Maybe<SortEnumType>;
  fldNok2mobile?: Maybe<SortEnumType>;
  fldNok2email?: Maybe<SortEnumType>;
  dateHire?: Maybe<SortEnumType>;
  fldSalary?: Maybe<SortEnumType>;
  fldCurrentPay?: Maybe<SortEnumType>;
  dateFire?: Maybe<SortEnumType>;
  fldBank?: Maybe<SortEnumType>;
  fldAcct?: Maybe<SortEnumType>;
  fldWire?: Maybe<SortEnumType>;
  datePayRev?: Maybe<SortEnumType>;
  fldVacDays?: Maybe<SortEnumType>;
  fldVacMeas?: Maybe<SortEnumType>;
  dateVac?: Maybe<SortEnumType>;
  fldRemarks?: Maybe<SortEnumType>;
  fldNameupper?: Maybe<SortEnumType>;
  fldIsGuest?: Maybe<SortEnumType>;
  fldMarital?: Maybe<SortEnumType>;
  fldPlaceOfBirth?: Maybe<SortEnumType>;
  fldBankPhone?: Maybe<SortEnumType>;
  fldBankFax?: Maybe<SortEnumType>;
  fldBankEmail?: Maybe<SortEnumType>;
  fldBankContact?: Maybe<SortEnumType>;
  fldBankRouting?: Maybe<SortEnumType>;
  fldBankSwift?: Maybe<SortEnumType>;
  fldBankIban?: Maybe<SortEnumType>;
  fldBankCurr?: Maybe<SortEnumType>;
  departmentHead?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  fldIsSo?: Maybe<SortEnumType>;
  fldIsSso?: Maybe<SortEnumType>;
  fldIsWatchkeeper?: Maybe<SortEnumType>;
  fldMedConsent?: Maybe<SortEnumType>;
  fldBloodAbo?: Maybe<SortEnumType>;
  fldBloodRh?: Maybe<SortEnumType>;
  fldSeaDis?: Maybe<SortEnumType>;
  fldCertsOnBoard?: Maybe<SortEnumType>;
  fldCrewFamiliar?: Maybe<SortEnumType>;
  fldIsAdmin?: Maybe<SortEnumType>;
  fldDefaultPassport?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type CrewSortInput = {
  fldCrewId?: Maybe<SortEnumType>;
  fldFirst?: Maybe<SortEnumType>;
  fldLast?: Maybe<SortEnumType>;
  crewUsername?: Maybe<SortEnumType>;
};

export type Currency = {
  __typename?: 'Currency';
  id: Scalars['String'];
  curr: Scalars['String'];
  abbreviation?: Maybe<Scalars['String']>;
  symbol?: Maybe<Scalars['String']>;
  symbolBefore?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type CurrencySortInput = {
  id?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  abbreviation?: Maybe<SortEnumType>;
  symbol?: Maybe<SortEnumType>;
  symbolBefore?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};



export type DeleteLogEntryPayload = {
  __typename?: 'DeleteLogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentRevision = {
  __typename?: 'DocumentRevision';
  id: Scalars['String'];
  documentationId: Scalars['String'];
  fileName?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  binderIndex?: Maybe<Scalars['String']>;
  binderLocation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  approvedByPm?: Maybe<Scalars['DateTime']>;
  approvedByPe?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fileType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
};

export type DocumentRevisionInput = {
  id?: Maybe<Scalars['String']>;
  documentationId?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  revision?: Maybe<Scalars['String']>;
  revisionDate?: Maybe<Scalars['DateTime']>;
  binderIndex?: Maybe<Scalars['String']>;
  binderLocation?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  page?: Maybe<Scalars['String']>;
  approvedByPm?: Maybe<Scalars['DateTime']>;
  approvedByPe?: Maybe<Scalars['DateTime']>;
  altPath?: Maybe<Scalars['Int']>;
  locationId?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fileType?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

export type DocumentRevisionPayload = {
  __typename?: 'DocumentRevisionPayload';
  documentRevision?: Maybe<DocumentRevision>;
  errors?: Maybe<Array<UserError>>;
};

export type Documentation = {
  __typename?: 'Documentation';
  id: Scalars['String'];
  docTitle: Scalars['String'];
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  documentRevisions: Array<DocumentRevision>;
};

/** A segment of a collection. */
export type DocumentationCollectionSegment = {
  __typename?: 'DocumentationCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Documentation>>;
  totalCount: Scalars['Int'];
};

export type DocumentationFilterInput = {
  showInCentral?: Maybe<Scalars['Boolean']>;
  libType?: Maybe<DocumentationLibType>;
  docTitle?: Maybe<Scalars['String']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  searchText?: Maybe<Scalars['String']>;
};

export type DocumentationInput = {
  id?: Maybe<Scalars['String']>;
  docTitle?: Maybe<Scalars['String']>;
  received?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  docPage?: Maybe<Scalars['String']>;
  docFigure?: Maybe<Scalars['String']>;
  addedBy?: Maybe<Scalars['String']>;
  toc?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  specRef?: Maybe<Scalars['String']>;
  showInCentral?: Maybe<Scalars['Boolean']>;
  isReport?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  libType?: Maybe<Scalars['String']>;
  completeReqd?: Maybe<Scalars['Boolean']>;
  requiresVerification?: Maybe<Scalars['Boolean']>;
  fileType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  statusInternal?: Maybe<Scalars['String']>;
};

export enum DocumentationLibType {
  Manuals = 'MANUALS',
  Drawings = 'DRAWINGS',
  Msds = 'MSDS',
  Photos = 'PHOTOS',
  Smsdocs = 'SMSDOCS',
  Ispsdocs = 'ISPSDOCS'
}

export type DocumentationListPayload = {
  __typename?: 'DocumentationListPayload';
  documentationList?: Maybe<Array<Documentation>>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentationPayload = {
  __typename?: 'DocumentationPayload';
  documentation?: Maybe<Documentation>;
  errors?: Maybe<Array<UserError>>;
};

export type DocumentationSortInput = {
  id?: Maybe<SortEnumType>;
  docTitle?: Maybe<SortEnumType>;
  received?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  docPage?: Maybe<SortEnumType>;
  docFigure?: Maybe<SortEnumType>;
  addedBy?: Maybe<SortEnumType>;
  toc?: Maybe<SortEnumType>;
  number?: Maybe<SortEnumType>;
  specRef?: Maybe<SortEnumType>;
  showInCentral?: Maybe<SortEnumType>;
  isReport?: Maybe<SortEnumType>;
  password?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  libType?: Maybe<SortEnumType>;
  completeReqd?: Maybe<SortEnumType>;
  requiresVerification?: Maybe<SortEnumType>;
  fileType?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  status?: Maybe<SortEnumType>;
  statusInternal?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type DrillSchedulesCollectionSegment = {
  __typename?: 'DrillSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type Equipment = {
  __typename?: 'Equipment';
  id: Scalars['String'];
  uniqueName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hours?: Maybe<Scalars['Int']>;
  parent?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Int']>;
  manufacturer?: Maybe<Scalars['String']>;
  modelNumber?: Maybe<Scalars['String']>;
  rating?: Maybe<Scalars['String']>;
  powerRequired?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  voltage?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  weightQty?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  serialNum?: Maybe<Scalars['String']>;
  artNumber?: Maybe<Scalars['String']>;
  arrangementNum?: Maybe<Scalars['String']>;
  drawingRef?: Maybe<Scalars['String']>;
  circuitNumber?: Maybe<Scalars['String']>;
  breaker?: Maybe<Scalars['String']>;
  distBoard?: Maybe<Scalars['String']>;
  supplier?: Maybe<Scalars['String']>;
  countHours?: Maybe<Scalars['Boolean']>;
  hoursOffset?: Maybe<Scalars['Int']>;
  dateReset?: Maybe<Scalars['DateTime']>;
  sms?: Maybe<Scalars['Boolean']>;
  locationId?: Maybe<Scalars['String']>;
  expireDate?: Maybe<Scalars['DateTime']>;
  day?: Maybe<Scalars['Byte']>;
  month?: Maybe<Scalars['String']>;
  year?: Maybe<Scalars['String']>;
  restricted?: Maybe<Scalars['Boolean']>;
  hrsEst?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  inService?: Maybe<Scalars['DateTime']>;
  reportingType?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

/** A segment of a collection. */
export type EquipmentCollectionSegment = {
  __typename?: 'EquipmentCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Equipment>>;
  totalCount: Scalars['Int'];
};

export type EquipmentFilterInput = {
  id?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  uniqueName?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type EquipmentSchedule = {
  __typename?: 'EquipmentSchedule';
  equipment?: Maybe<Equipment>;
  id: Scalars['String'];
  subject: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hourInterval?: Maybe<Scalars['Int']>;
  timeInterval?: Maybe<Scalars['Int']>;
  timeMeasure?: Maybe<Scalars['String']>;
  hourWarn?: Maybe<Scalars['Int']>;
  timeWarn?: Maybe<Scalars['Int']>;
  timeMeasureWarn?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  recurType?: Maybe<Scalars['Byte']>;
  assignedTo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  recurInterval?: Maybe<Scalars['Int']>;
  recurPattern?: Maybe<Scalars['String']>;
  toolsReqd?: Maybe<Scalars['String']>;
  hourLock?: Maybe<Scalars['Boolean']>;
  ttlwork?: Maybe<Scalars['String']>;
  csm?: Maybe<Scalars['Boolean']>;
  schedType?: Maybe<Scalars['String']>;
  userDefined?: Maybe<Scalars['String']>;
  listType?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  linkId?: Maybe<Scalars['String']>;
  quotedPrice?: Maybe<Scalars['Decimal']>;
  quotedCurrency?: Maybe<Scalars['String']>;
  rtf?: Maybe<Scalars['String']>;
  plainText?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  equipmentId?: Maybe<Scalars['String']>;
  hoursTrigger?: Maybe<Scalars['Int']>;
  hoursCompleted?: Maybe<Scalars['Int']>;
  dateTrigger?: Maybe<Scalars['DateTime']>;
  runOnce?: Maybe<Scalars['Boolean']>;
  iterations?: Maybe<Scalars['Int']>;
  deferred?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  deferredDate?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
};

export type EquipmentScheduleFilterInput = {
  subject?: Maybe<Scalars['String']>;
  equipmentName?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type EquipmentScheduleSortInput = {
  equipment?: Maybe<EquipmentSortInput>;
  id?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hourInterval?: Maybe<SortEnumType>;
  timeInterval?: Maybe<SortEnumType>;
  timeMeasure?: Maybe<SortEnumType>;
  hourWarn?: Maybe<SortEnumType>;
  timeWarn?: Maybe<SortEnumType>;
  timeMeasureWarn?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  recurType?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  recurInterval?: Maybe<SortEnumType>;
  recurPattern?: Maybe<SortEnumType>;
  toolsReqd?: Maybe<SortEnumType>;
  hourLock?: Maybe<SortEnumType>;
  ttlwork?: Maybe<SortEnumType>;
  csm?: Maybe<SortEnumType>;
  schedType?: Maybe<SortEnumType>;
  userDefined?: Maybe<SortEnumType>;
  listType?: Maybe<SortEnumType>;
  duration?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  linkId?: Maybe<SortEnumType>;
  quotedPrice?: Maybe<SortEnumType>;
  quotedCurrency?: Maybe<SortEnumType>;
  rtf?: Maybe<SortEnumType>;
  plainText?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  hoursTrigger?: Maybe<SortEnumType>;
  hoursCompleted?: Maybe<SortEnumType>;
  dateTrigger?: Maybe<SortEnumType>;
  runOnce?: Maybe<SortEnumType>;
  iterations?: Maybe<SortEnumType>;
  deferred?: Maybe<SortEnumType>;
  index?: Maybe<SortEnumType>;
  deferredDate?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type EquipmentSchedulesCollectionSegment = {
  __typename?: 'EquipmentSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<EquipmentSchedule>>;
  totalCount: Scalars['Int'];
};

export type EquipmentSortInput = {
  id?: Maybe<SortEnumType>;
  uniqueName?: Maybe<SortEnumType>;
  name?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hours?: Maybe<SortEnumType>;
  parent?: Maybe<SortEnumType>;
  amount?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  rating?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  voltage?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  searchMod?: Maybe<SortEnumType>;
  searchMaker?: Maybe<SortEnumType>;
  serialNum?: Maybe<SortEnumType>;
  artNumber?: Maybe<SortEnumType>;
  arrangementNum?: Maybe<SortEnumType>;
  drawingRef?: Maybe<SortEnumType>;
  circuitNumber?: Maybe<SortEnumType>;
  breaker?: Maybe<SortEnumType>;
  distBoard?: Maybe<SortEnumType>;
  supplier?: Maybe<SortEnumType>;
  countHours?: Maybe<SortEnumType>;
  hoursOffset?: Maybe<SortEnumType>;
  dateReset?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
  expireDate?: Maybe<SortEnumType>;
  day?: Maybe<SortEnumType>;
  month?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  restricted?: Maybe<SortEnumType>;
  hrsEst?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  inService?: Maybe<SortEnumType>;
  reportingType?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type EventSchedulesCollectionSegment = {
  __typename?: 'EventSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type GridSavedViewInput = {
  pkey?: Maybe<Scalars['String']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldLayout: Scalars['String'];
  fldModule: Scalars['String'];
  fldViewName: Scalars['String'];
};

export type GridSavedViewPayload = {
  __typename?: 'GridSavedViewPayload';
  view?: Maybe<TblGridSavedView>;
  errors?: Maybe<Array<UserError>>;
};

export type GroupCountOfCategory = {
  __typename?: 'GroupCountOfCategory';
  item?: Maybe<TblSystemsHierarchy>;
  count: Scalars['Int'];
};

export type GroupCountOfLocation = {
  __typename?: 'GroupCountOfLocation';
  item?: Maybe<TblLocationsHierarchy>;
  count: Scalars['Int'];
};

export type GroupListOfEquipmentAndLogEntry = {
  __typename?: 'GroupListOfEquipmentAndLogEntry';
  item?: Maybe<Equipment>;
  list?: Maybe<Array<Maybe<LogEntry>>>;
  count: Scalars['Int'];
};

/** A segment of a collection. */
export type InspectionsSchedulesCollectionSegment = {
  __typename?: 'InspectionsSchedulesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Schedule>>;
  totalCount: Scalars['Int'];
};

export type Inventory = {
  __typename?: 'Inventory';
  id: Scalars['String'];
  productName: Scalars['String'];
  productDescription?: Maybe<Scalars['String']>;
  unitsOnOrder?: Maybe<Scalars['Int']>;
  reOrderLevel?: Maybe<Scalars['Int']>;
  modelNumber?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  reOrder?: Maybe<Scalars['Boolean']>;
  manufacturer?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  searchMod?: Maybe<Scalars['String']>;
  searchMaker?: Maybe<Scalars['String']>;
  searchPart?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Decimal']>;
  powerRequired?: Maybe<Scalars['String']>;
  weightQty?: Maybe<Scalars['String']>;
  bonded?: Maybe<Scalars['Boolean']>;
  noView?: Maybe<Scalars['Boolean']>;
  consumable?: Maybe<Scalars['Boolean']>;
  useQty?: Maybe<Scalars['String']>;
  factor?: Maybe<Scalars['Decimal']>;
  orderQty?: Maybe<Scalars['String']>;
  orderAmt?: Maybe<Scalars['Int']>;
  reOrderAmt?: Maybe<Scalars['Int']>;
  dataType?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  llbc?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  guestType?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  rx?: Maybe<Scalars['Boolean']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  multipleLocations: Array<MultipleLocation>;
};

/** A segment of a collection. */
export type InventoryCollectionSegment = {
  __typename?: 'InventoryCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<Inventory>>;
  totalCount: Scalars['Int'];
};

export type InventoryFilterInput = {
  dataType?: Maybe<Scalars['String']>;
  productName?: Maybe<Scalars['String']>;
  manufacturer?: Maybe<Scalars['String']>;
  partNumber?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type InventoryLocation = {
  __typename?: 'InventoryLocation';
  inventory: Inventory;
  amount?: Maybe<Scalars['Decimal']>;
  locationPath?: Maybe<Scalars['String']>;
};

export type InventoryLocationPayload = {
  __typename?: 'InventoryLocationPayload';
  inventoryLocations?: Maybe<Array<InventoryLocation>>;
  errors?: Maybe<Array<UserError>>;
};

export type InventorySortInput = {
  id?: Maybe<SortEnumType>;
  productName?: Maybe<SortEnumType>;
  productDescription?: Maybe<SortEnumType>;
  unitsOnOrder?: Maybe<SortEnumType>;
  reOrderLevel?: Maybe<SortEnumType>;
  modelNumber?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  reOrder?: Maybe<SortEnumType>;
  manufacturer?: Maybe<SortEnumType>;
  qty?: Maybe<SortEnumType>;
  searchMod?: Maybe<SortEnumType>;
  searchMaker?: Maybe<SortEnumType>;
  searchPart?: Maybe<SortEnumType>;
  weight?: Maybe<SortEnumType>;
  powerRequired?: Maybe<SortEnumType>;
  weightQty?: Maybe<SortEnumType>;
  bonded?: Maybe<SortEnumType>;
  noView?: Maybe<SortEnumType>;
  consumable?: Maybe<SortEnumType>;
  useQty?: Maybe<SortEnumType>;
  factor?: Maybe<SortEnumType>;
  orderQty?: Maybe<SortEnumType>;
  orderAmt?: Maybe<SortEnumType>;
  reOrderAmt?: Maybe<SortEnumType>;
  dataType?: Maybe<SortEnumType>;
  region?: Maybe<SortEnumType>;
  country?: Maybe<SortEnumType>;
  size?: Maybe<SortEnumType>;
  color?: Maybe<SortEnumType>;
  llbc?: Maybe<SortEnumType>;
  partNumber?: Maybe<SortEnumType>;
  guestType?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  rx?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

export type LocationSortInput = {
  pkey?: Maybe<SortEnumType>;
  fldLocation?: Maybe<SortEnumType>;
  parentId?: Maybe<SortEnumType>;
  fldTags?: Maybe<SortEnumType>;
  fldDepts?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
};

/** A segment of a collection. */
export type LogEntriesCollectionSegment = {
  __typename?: 'LogEntriesCollectionSegment';
  /** Information to aid in pagination. */
  pageInfo: CollectionSegmentInfo;
  /** A flattened list of the items. */
  items?: Maybe<Array<LogEntry>>;
  totalCount: Scalars['Int'];
};

export type LogEntry = {
  __typename?: 'LogEntry';
  pkey: Scalars['String'];
  description: Scalars['String'];
  logDate: Scalars['DateTime'];
  month: Scalars['String'];
  year: Scalars['String'];
  equipmentName?: Maybe<Scalars['String']>;
  dayLogKey?: Maybe<Scalars['String']>;
  maintKey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  jobNumber?: Maybe<Scalars['String']>;
  workIssue?: Maybe<WorkIssue>;
  dayLogType?: Maybe<Scalars['Byte']>;
  fldCost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  fldCompany?: Maybe<Scalars['String']>;
  fldTime?: Maybe<Scalars['Decimal']>;
  fldTimeQty?: Maybe<Scalars['String']>;
  fldHours?: Maybe<Scalars['Int']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  fldTsachk?: Maybe<Scalars['Boolean']>;
  fldGeoLocation?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldLogEntryTime?: Maybe<Scalars['DateTime']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldBillable?: Maybe<Scalars['Boolean']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  fldLogEntryRtf?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  location?: Maybe<TblLocationsHierarchy>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldRelEntry?: Maybe<Scalars['Boolean']>;
  fldIsCheckList?: Maybe<Scalars['Boolean']>;
  fldWorkList?: Maybe<Scalars['String']>;
  fldRelatedKey?: Maybe<Scalars['String']>;
  fldHtml?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  category?: Maybe<TblSystemsHierarchy>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  checks?: Maybe<Scalars['String']>;
  equipment?: Maybe<Equipment>;
  attachments: Array<Attachment>;
  photosCount: Scalars['Int'];
  documentsCount: Scalars['Int'];
  sparesUsedCount: Scalars['Int'];
  commentsCount: Scalars['Int'];
};

export enum LogEntryFilterBy {
  Category = 'CATEGORY',
  Location = 'LOCATION'
}

export type LogEntryFilterInput = {
  logDateStart?: Maybe<Scalars['DateTime']>;
  logDateEnd?: Maybe<Scalars['DateTime']>;
  fldHtml?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  fldWorkList?: Maybe<Scalars['String']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  searchText?: Maybe<Scalars['String']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  eqKey?: Maybe<Scalars['String']>;
  maintKey?: Maybe<Scalars['String']>;
};

export type LogEntryInput = {
  pKey?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  logDate?: Maybe<Scalars['DateTime']>;
  eqKey?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  fldWorkList?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  fldHours?: Maybe<Scalars['Int']>;
  fldEnteredBy?: Maybe<Scalars['String']>;
  fldPerformedBy?: Maybe<Scalars['String']>;
  fldCompany?: Maybe<Scalars['String']>;
  fldTime?: Maybe<Scalars['Decimal']>;
  fldTimeQty?: Maybe<Scalars['String']>;
  fldCost?: Maybe<Scalars['Decimal']>;
  curr?: Maybe<Scalars['String']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  fldHtml?: Maybe<Scalars['String']>;
  fldCrewId?: Maybe<Scalars['String']>;
  fldRestricted?: Maybe<Scalars['Boolean']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  dayLogType?: Maybe<Scalars['Byte']>;
};

export type LogEntryPayload = {
  __typename?: 'LogEntryPayload';
  logEntry?: Maybe<LogEntry>;
  errors?: Maybe<Array<UserError>>;
};

export type LogEntrySortInput = {
  pkey?: Maybe<SortEnumType>;
  description?: Maybe<SortEnumType>;
  logDate?: Maybe<SortEnumType>;
  month?: Maybe<SortEnumType>;
  year?: Maybe<SortEnumType>;
  equipmentName?: Maybe<SortEnumType>;
  dayLogKey?: Maybe<SortEnumType>;
  maintKey?: Maybe<SortEnumType>;
  eqKey?: Maybe<SortEnumType>;
  jobNumber?: Maybe<SortEnumType>;
  workIssue?: Maybe<WorkIssueSortInput>;
  dayLogType?: Maybe<SortEnumType>;
  fldCost?: Maybe<SortEnumType>;
  curr?: Maybe<SortEnumType>;
  fldCompany?: Maybe<SortEnumType>;
  fldTime?: Maybe<SortEnumType>;
  fldTimeQty?: Maybe<SortEnumType>;
  fldHours?: Maybe<SortEnumType>;
  fldPerformedBy?: Maybe<SortEnumType>;
  fldEnteredBy?: Maybe<SortEnumType>;
  fldTsachk?: Maybe<SortEnumType>;
  fldGeoLocation?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  fldLogEntryTime?: Maybe<SortEnumType>;
  fldCrewId?: Maybe<SortEnumType>;
  fldNoView?: Maybe<SortEnumType>;
  fldRestricted?: Maybe<SortEnumType>;
  fldBillable?: Maybe<SortEnumType>;
  fldIsWarranty?: Maybe<SortEnumType>;
  fldLogEntryRtf?: Maybe<SortEnumType>;
  fldLocHierarchy?: Maybe<SortEnumType>;
  location?: Maybe<LocationSortInput>;
  dateEntered?: Maybe<SortEnumType>;
  fldRelEntry?: Maybe<SortEnumType>;
  fldIsCheckList?: Maybe<SortEnumType>;
  fldWorkList?: Maybe<SortEnumType>;
  fldRelatedKey?: Maybe<SortEnumType>;
  fldHtml?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  category?: Maybe<CategorySortInput>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  fldSms?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
  checks?: Maybe<SortEnumType>;
  equipment?: Maybe<EquipmentSortInput>;
  photosCount?: Maybe<SortEnumType>;
  documentsCount?: Maybe<SortEnumType>;
  sparesUsedCount?: Maybe<SortEnumType>;
  commentsCount?: Maybe<SortEnumType>;
};

export type ModifyCommentInput = {
  id: Scalars['String'];
  content: Scalars['String'];
};

export type ModifyLogEntriesPayload = {
  __typename?: 'ModifyLogEntriesPayload';
  logEntries?: Maybe<Array<LogEntry>>;
  errors?: Maybe<Array<UserError>>;
};

export type MultipleLocation = {
  __typename?: 'MultipleLocation';
  pkey: Scalars['String'];
  productId: Scalars['String'];
  serialNum?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  useBy?: Maybe<Scalars['DateTime']>;
  fldDefault?: Maybe<Scalars['Boolean']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldMaxHldgLoc?: Maybe<Scalars['Decimal']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  product: Inventory;
  location?: Maybe<TblLocationsHierarchy>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addAttachment: AttachmentPayload;
  modifyAttachment: AttachmentPayload;
  upsertAttachment: AttachmentPayload;
  deleteAttachmentFromRecord: AttachmentPayload;
  addComment: CommentPayload;
  modifyComment: CommentPayload;
  deleteComment: CommentPayload;
  addDocumentation: DocumentationPayload;
  modifyDocumentation: DocumentationPayload;
  upsertDocumentation: DocumentationPayload;
  deleteDocumentation: DocumentationPayload;
  deleteDocumentations: DocumentationListPayload;
  addDocumentRevision: DocumentRevisionPayload;
  modifyDocumentRevision: DocumentRevisionPayload;
  upsertDocumentRevision: DocumentRevisionPayload;
  deleteDocumentRevision: DocumentRevisionPayload;
  addLogEntry: LogEntryPayload;
  upsertLogEntry?: Maybe<LogEntryPayload>;
  modifyLogEntry?: Maybe<LogEntryPayload>;
  deleteLogEntry?: Maybe<DeleteLogEntryPayload>;
  deleteLogEntries?: Maybe<ModifyLogEntriesPayload>;
  addSpare: SparePayload;
  modifySpare: SparePayload;
  upsertSpare: SparePayload;
  deleteSpare: SparePayload;
  upsertGridSavedView: GridSavedViewPayload;
  createGridSavedView: GridSavedViewPayload;
  modifyGridSavedView: GridSavedViewPayload;
  deleteGridSavedView: GridSavedViewPayload;
};


export type MutationAddAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationModifyAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationUpsertAttachmentArgs = {
  input: AttachmentInput;
};


export type MutationDeleteAttachmentFromRecordArgs = {
  input: AttachmentCrossReferenceInput;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationModifyCommentArgs = {
  input: ModifyCommentInput;
};


export type MutationDeleteCommentArgs = {
  commentId: Scalars['String'];
};


export type MutationAddDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationModifyDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationUpsertDocumentationArgs = {
  input: DocumentationInput;
};


export type MutationDeleteDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type MutationDeleteDocumentationsArgs = {
  documentationIds: Array<Scalars['String']>;
};


export type MutationAddDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationModifyDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationUpsertDocumentRevisionArgs = {
  input: DocumentRevisionInput;
};


export type MutationDeleteDocumentRevisionArgs = {
  documentRevisionId: Scalars['String'];
};


export type MutationAddLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationUpsertLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationModifyLogEntryArgs = {
  input: LogEntryInput;
};


export type MutationDeleteLogEntryArgs = {
  logEntryId: Scalars['String'];
};


export type MutationDeleteLogEntriesArgs = {
  logEntryIds: Array<Scalars['String']>;
};


export type MutationAddSpareArgs = {
  input: SparesUsedInput;
};


export type MutationModifySpareArgs = {
  input: SparesUsedInput;
};


export type MutationUpsertSpareArgs = {
  input: SparesUsedInput;
};


export type MutationDeleteSpareArgs = {
  spareId: Scalars['String'];
};


export type MutationUpsertGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationCreateGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationModifyGridSavedViewArgs = {
  input: GridSavedViewInput;
};


export type MutationDeleteGridSavedViewArgs = {
  gridSavedViewId: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  attachmentsForRecords: Array<Attachment>;
  attachmentsForRecord: Array<Attachment>;
  attachmentById?: Maybe<Attachment>;
  attachmentsForDocumentation: Array<Attachment>;
  categories: Array<TblSystemsHierarchy>;
  commentsForRecord: Array<Comment>;
  companies: Array<Company>;
  companyNames: Array<CompanyName>;
  crewNames: Array<Crew>;
  currentUser: Crew;
  currencies: Array<Currency>;
  documentation?: Maybe<DocumentationCollectionSegment>;
  documentRevisionsForDocumentation?: Maybe<Array<DocumentRevision>>;
  equipment?: Maybe<EquipmentCollectionSegment>;
  equipmentById?: Maybe<Equipment>;
  inventory?: Maybe<InventoryCollectionSegment>;
  inventoryForEquipment: Array<Inventory>;
  inventoryById?: Maybe<Inventory>;
  inventoryLocations: InventoryLocationPayload;
  list: Array<TblDdlistDefault>;
  locations: Array<TblLocationsHierarchy>;
  logEntries?: Maybe<LogEntriesCollectionSegment>;
  logEntry?: Maybe<LogEntry>;
  logEntriesGroupedByEquipment?: Maybe<Array<GroupListOfEquipmentAndLogEntry>>;
  logEntryCountsByCategory: Array<GroupCountOfCategory>;
  logEntryCountsByLocation: Array<GroupCountOfLocation>;
  equipmentSchedules?: Maybe<EquipmentSchedulesCollectionSegment>;
  eventSchedules?: Maybe<EventSchedulesCollectionSegment>;
  drillSchedules?: Maybe<DrillSchedulesCollectionSegment>;
  inspectionsSchedules?: Maybe<InspectionsSchedulesCollectionSegment>;
  sparesUsed: Array<TblSparesUsed>;
  gridSavedViews: Array<TblGridSavedView>;
};


export type QueryAttachmentsForRecordsArgs = {
  recordIds: Array<Scalars['String']>;
  attachmentType: AttachmentType;
  order?: Maybe<Array<AttachmentSortInput>>;
};


export type QueryAttachmentsForRecordArgs = {
  recordId: Scalars['String'];
  recordType: RecordType;
  attachmentType: AttachmentType;
};


export type QueryAttachmentByIdArgs = {
  attachmentId: Scalars['String'];
};


export type QueryAttachmentsForDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type QueryCommentsForRecordArgs = {
  recordId: Scalars['String'];
  order?: Maybe<Array<CommentSortInput>>;
};


export type QueryCompaniesArgs = {
  where?: Maybe<CompanyFilterInput>;
  order?: Maybe<Array<CompanySortInput>>;
};


export type QueryCrewNamesArgs = {
  order?: Maybe<Array<CrewSortInput>>;
};


export type QueryCurrenciesArgs = {
  order?: Maybe<Array<CurrencySortInput>>;
};


export type QueryDocumentationArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<DocumentationFilterInput>;
  order?: Maybe<Array<DocumentationSortInput>>;
};


export type QueryDocumentRevisionsForDocumentationArgs = {
  documentationId: Scalars['String'];
};


export type QueryEquipmentArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<EquipmentFilterInput>;
  order?: Maybe<Array<EquipmentSortInput>>;
};


export type QueryEquipmentByIdArgs = {
  eqKey: Scalars['String'];
};


export type QueryInventoryArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<InventoryFilterInput>;
  order?: Maybe<Array<InventorySortInput>>;
};


export type QueryInventoryForEquipmentArgs = {
  equipmentId: Scalars['String'];
};


export type QueryInventoryByIdArgs = {
  productId: Scalars['String'];
};


export type QueryInventoryLocationsArgs = {
  productId: Scalars['String'];
};


export type QueryListArgs = {
  listName: Scalars['String'];
};


export type QueryLogEntriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<LogEntryFilterInput>;
  order?: Maybe<Array<LogEntrySortInput>>;
};


export type QueryLogEntryArgs = {
  pKey: Scalars['String'];
};


export type QueryLogEntriesGroupedByEquipmentArgs = {
  filterById?: Maybe<Scalars['String']>;
  filterBy: LogEntryFilterBy;
  filterInput: LogEntryFilterInput;
};


export type QueryLogEntryCountsByCategoryArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryLogEntryCountsByLocationArgs = {
  filterInput?: Maybe<LogEntryFilterInput>;
};


export type QueryEquipmentSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<EquipmentScheduleFilterInput>;
  order?: Maybe<Array<EquipmentScheduleSortInput>>;
};


export type QueryEventSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QueryDrillSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QueryInspectionsSchedulesArgs = {
  skip?: Maybe<Scalars['Int']>;
  take?: Maybe<Scalars['Int']>;
  filterInput?: Maybe<ScheduleFilterInput>;
  order?: Maybe<Array<ScheduleSortInput>>;
};


export type QuerySparesUsedArgs = {
  logKey: Scalars['String'];
};


export type QueryGridSavedViewsArgs = {
  moduleName: Scalars['String'];
};

export enum RecordType {
  LogEntry = 'LOG_ENTRY',
  Equipment = 'EQUIPMENT',
  WorkIssue = 'WORK_ISSUE',
  InventoryGeneral = 'INVENTORY_GENERAL',
  InventoryBeverages = 'INVENTORY_BEVERAGES',
  InventoryUniform = 'INVENTORY_UNIFORM',
  InventoryDryAndColdStore = 'INVENTORY_DRY_AND_COLD_STORE',
  InventoryMedical = 'INVENTORY_MEDICAL',
  EquipmentSchedule = 'EQUIPMENT_SCHEDULE',
  EventSchedule = 'EVENT_SCHEDULE',
  DrillSchedule = 'DRILL_SCHEDULE',
  InspectionSchedule = 'INSPECTION_SCHEDULE',
  PurchaseOrder = 'PURCHASE_ORDER',
  EngineersDayLog = 'ENGINEERS_DAY_LOG',
  RunningLog = 'RUNNING_LOG',
  Crew = 'CREW',
  Guest = 'GUEST',
  SmsReport = 'SMS_REPORT',
  IspsReport = 'ISPS_REPORT',
  VesselCertificates = 'VESSEL_CERTIFICATES',
  CrewCertificates = 'CREW_CERTIFICATES',
  DocumentationVesselReference = 'DOCUMENTATION_VESSEL_REFERENCE',
  DocumentationDrawings = 'DOCUMENTATION_DRAWINGS',
  DocumentationManuals = 'DOCUMENTATION_MANUALS',
  DocumentationMsds = 'DOCUMENTATION_MSDS',
  DocumentationPhotos = 'DOCUMENTATION_PHOTOS',
  DocumentationSms = 'DOCUMENTATION_SMS',
  DocumentationIsps = 'DOCUMENTATION_ISPS',
  HoursOfRest = 'HOURS_OF_REST',
  Undefined = 'UNDEFINED'
}

export type Schedule = {
  __typename?: 'Schedule';
  id: Scalars['String'];
  subject: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  hoursKey?: Maybe<Scalars['String']>;
  hourInterval?: Maybe<Scalars['Int']>;
  timeInterval?: Maybe<Scalars['Int']>;
  timeMeasure?: Maybe<Scalars['String']>;
  hourWarn?: Maybe<Scalars['Int']>;
  timeWarn?: Maybe<Scalars['Int']>;
  timeMeasureWarn?: Maybe<Scalars['String']>;
  steps?: Maybe<Scalars['String']>;
  sms?: Maybe<Scalars['Boolean']>;
  recurType?: Maybe<Scalars['Byte']>;
  assignedTo?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  recurInterval?: Maybe<Scalars['Int']>;
  recurPattern?: Maybe<Scalars['String']>;
  toolsReqd?: Maybe<Scalars['String']>;
  hourLock?: Maybe<Scalars['Boolean']>;
  ttlwork?: Maybe<Scalars['String']>;
  csm?: Maybe<Scalars['Boolean']>;
  schedType?: Maybe<Scalars['String']>;
  userDefined?: Maybe<Scalars['String']>;
  listType?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  isCheckList?: Maybe<Scalars['Boolean']>;
  linkId?: Maybe<Scalars['String']>;
  quotedPrice?: Maybe<Scalars['Decimal']>;
  quotedCurrency?: Maybe<Scalars['String']>;
  rtf?: Maybe<Scalars['String']>;
  plainText?: Maybe<Scalars['String']>;
  html?: Maybe<Scalars['String']>;
  categoryId?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  equipmentId?: Maybe<Scalars['String']>;
  hoursTrigger?: Maybe<Scalars['Int']>;
  hoursCompleted?: Maybe<Scalars['Int']>;
  dateTrigger?: Maybe<Scalars['DateTime']>;
  runOnce?: Maybe<Scalars['Boolean']>;
  iterations?: Maybe<Scalars['Int']>;
  deferred?: Maybe<Scalars['Boolean']>;
  index?: Maybe<Scalars['Int']>;
  deferredDate?: Maybe<Scalars['DateTime']>;
  locationId?: Maybe<Scalars['String']>;
};

export type ScheduleFilterInput = {
  subject?: Maybe<Scalars['String']>;
  excludeIds?: Maybe<Array<Scalars['String']>>;
};

export type ScheduleSortInput = {
  id?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  hoursKey?: Maybe<SortEnumType>;
  hourInterval?: Maybe<SortEnumType>;
  timeInterval?: Maybe<SortEnumType>;
  timeMeasure?: Maybe<SortEnumType>;
  hourWarn?: Maybe<SortEnumType>;
  timeWarn?: Maybe<SortEnumType>;
  timeMeasureWarn?: Maybe<SortEnumType>;
  steps?: Maybe<SortEnumType>;
  sms?: Maybe<SortEnumType>;
  recurType?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  recurInterval?: Maybe<SortEnumType>;
  recurPattern?: Maybe<SortEnumType>;
  toolsReqd?: Maybe<SortEnumType>;
  hourLock?: Maybe<SortEnumType>;
  ttlwork?: Maybe<SortEnumType>;
  csm?: Maybe<SortEnumType>;
  schedType?: Maybe<SortEnumType>;
  userDefined?: Maybe<SortEnumType>;
  listType?: Maybe<SortEnumType>;
  duration?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  isCheckList?: Maybe<SortEnumType>;
  linkId?: Maybe<SortEnumType>;
  quotedPrice?: Maybe<SortEnumType>;
  quotedCurrency?: Maybe<SortEnumType>;
  rtf?: Maybe<SortEnumType>;
  plainText?: Maybe<SortEnumType>;
  html?: Maybe<SortEnumType>;
  categoryId?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  equipmentId?: Maybe<SortEnumType>;
  hoursTrigger?: Maybe<SortEnumType>;
  hoursCompleted?: Maybe<SortEnumType>;
  dateTrigger?: Maybe<SortEnumType>;
  runOnce?: Maybe<SortEnumType>;
  iterations?: Maybe<SortEnumType>;
  deferred?: Maybe<SortEnumType>;
  index?: Maybe<SortEnumType>;
  deferredDate?: Maybe<SortEnumType>;
  locationId?: Maybe<SortEnumType>;
};

export enum SortEnumType {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type SparePayload = {
  __typename?: 'SparePayload';
  spare?: Maybe<TblSparesUsed>;
  errors?: Maybe<Array<UserError>>;
};

export type SparesUsedInput = {
  pkey?: Maybe<Scalars['String']>;
  logKey: Scalars['String'];
  productId: Scalars['String'];
  amount: Scalars['Int'];
};

export type StringOperationFilterInput = {
  and?: Maybe<Array<StringOperationFilterInput>>;
  or?: Maybe<Array<StringOperationFilterInput>>;
  eq?: Maybe<Scalars['String']>;
  neq?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  ncontains?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Maybe<Scalars['String']>>>;
  nin?: Maybe<Array<Maybe<Scalars['String']>>>;
  startsWith?: Maybe<Scalars['String']>;
  nstartsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  nendsWith?: Maybe<Scalars['String']>;
};

export type TblDdlistDefault = {
  __typename?: 'TblDdlistDefault';
  pkey: Scalars['String'];
  fldListName?: Maybe<Scalars['String']>;
  fldMember?: Maybe<Scalars['String']>;
  fldT4reqd: Scalars['Boolean'];
  fldIndex?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblGridSavedView = {
  __typename?: 'TblGridSavedView';
  pkey: Scalars['String'];
  fldCrewId?: Maybe<Scalars['String']>;
  fldLayout?: Maybe<Scalars['String']>;
  fldModule?: Maybe<Scalars['String']>;
  fldViewName?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblLocationsHierarchy = {
  __typename?: 'TblLocationsHierarchy';
  pkey: Scalars['String'];
  fldLocation: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  fldTags?: Maybe<Scalars['String']>;
  fldDepts?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblSchedChk = {
  __typename?: 'TblSchedChk';
  pkey: Scalars['String'];
  fkey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  fldTitle?: Maybe<Scalars['String']>;
  completed?: Maybe<Scalars['Boolean']>;
  fldIndex?: Maybe<Scalars['Int']>;
  updatedAt: Scalars['DateTime'];
  deletedAt?: Maybe<Scalars['DateTime']>;
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type TblSparesUsed = {
  __typename?: 'TblSparesUsed';
  pkey: Scalars['String'];
  productId: Scalars['String'];
  logKey?: Maybe<Scalars['String']>;
  eqKey?: Maybe<Scalars['String']>;
  workKey?: Maybe<Scalars['String']>;
  multiKey?: Maybe<Scalars['String']>;
  qty?: Maybe<Scalars['String']>;
  amount?: Maybe<Scalars['Decimal']>;
  fldDateUsed?: Maybe<Scalars['DateTime']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldUpdateInvOnSave?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  inventory: Inventory;
};

export type TblSystemsHierarchy = {
  __typename?: 'TblSystemsHierarchy';
  pkey: Scalars['String'];
  fldCategory: Scalars['String'];
  parentId?: Maybe<Scalars['String']>;
  fldTags?: Maybe<Scalars['String']>;
  fldDepts?: Maybe<Scalars['String']>;
  fldNoView?: Maybe<Scalars['Boolean']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
};

export type UserError = {
  __typename?: 'UserError';
  message: Scalars['String'];
  code: Scalars['String'];
};

export type WorkIssue = {
  __typename?: 'WorkIssue';
  jobNumber: Scalars['String'];
  subject: Scalars['String'];
  eqKey?: Maybe<Scalars['String']>;
  fldSchedMaintKey?: Maybe<Scalars['String']>;
  fldSmstype?: Maybe<Scalars['String']>;
  fldSmsid?: Maybe<Scalars['String']>;
  equipment?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  fldDeptCreator?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  percentComplete?: Maybe<Scalars['Decimal']>;
  dateDue?: Maybe<Scalars['DateTime']>;
  dateStart?: Maybe<Scalars['DateTime']>;
  dateWarn?: Maybe<Scalars['DateTime']>;
  completed?: Maybe<Scalars['Boolean']>;
  dateCompleted?: Maybe<Scalars['DateTime']>;
  workItem?: Maybe<Scalars['Boolean']>;
  fldStatus?: Maybe<Scalars['String']>;
  fldPriority?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['String']>;
  fldTtlwork?: Maybe<Scalars['String']>;
  fldActualWork?: Maybe<Scalars['String']>;
  fldLogOnDone?: Maybe<Scalars['Boolean']>;
  fldDayChk?: Maybe<Scalars['Byte']>;
  fldIsWarranty?: Maybe<Scalars['Boolean']>;
  fldWorkList?: Maybe<Scalars['String']>;
  fldHasBeenLogged?: Maybe<Scalars['Boolean']>;
  fldSms?: Maybe<Scalars['Boolean']>;
  fldQuotedPrice?: Maybe<Scalars['Decimal']>;
  fldQuotedCurrency?: Maybe<Scalars['String']>;
  fldActualCost?: Maybe<Scalars['Decimal']>;
  fldActualCurrency?: Maybe<Scalars['String']>;
  fldPaid?: Maybe<Scalars['Boolean']>;
  fldHours?: Maybe<Scalars['Int']>;
  fldIsCar?: Maybe<Scalars['Boolean']>;
  fldLocHierarchy?: Maybe<Scalars['String']>;
  dateEntered?: Maybe<Scalars['DateTime']>;
  fldParentId?: Maybe<Scalars['String']>;
  fldDuration?: Maybe<Scalars['Int']>;
  fldIsCheckList?: Maybe<Scalars['Boolean']>;
  fldCustJob?: Maybe<Scalars['String']>;
  fldHtml?: Maybe<Scalars['String']>;
  fldSrhkey?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  deletedBy?: Maybe<Scalars['String']>;
  isRecoverable?: Maybe<Scalars['Boolean']>;
  autoId: Scalars['Int'];
  checks?: Maybe<Scalars['String']>;
};

export type WorkIssueSortInput = {
  jobNumber?: Maybe<SortEnumType>;
  subject?: Maybe<SortEnumType>;
  eqKey?: Maybe<SortEnumType>;
  fldSchedMaintKey?: Maybe<SortEnumType>;
  fldSmstype?: Maybe<SortEnumType>;
  fldSmsid?: Maybe<SortEnumType>;
  equipment?: Maybe<SortEnumType>;
  notes?: Maybe<SortEnumType>;
  fldDeptCreator?: Maybe<SortEnumType>;
  department?: Maybe<SortEnumType>;
  company?: Maybe<SortEnumType>;
  percentComplete?: Maybe<SortEnumType>;
  dateDue?: Maybe<SortEnumType>;
  dateStart?: Maybe<SortEnumType>;
  dateWarn?: Maybe<SortEnumType>;
  completed?: Maybe<SortEnumType>;
  dateCompleted?: Maybe<SortEnumType>;
  workItem?: Maybe<SortEnumType>;
  fldStatus?: Maybe<SortEnumType>;
  fldPriority?: Maybe<SortEnumType>;
  assignedTo?: Maybe<SortEnumType>;
  fldTtlwork?: Maybe<SortEnumType>;
  fldActualWork?: Maybe<SortEnumType>;
  fldLogOnDone?: Maybe<SortEnumType>;
  fldDayChk?: Maybe<SortEnumType>;
  fldIsWarranty?: Maybe<SortEnumType>;
  fldWorkList?: Maybe<SortEnumType>;
  fldHasBeenLogged?: Maybe<SortEnumType>;
  fldSms?: Maybe<SortEnumType>;
  fldQuotedPrice?: Maybe<SortEnumType>;
  fldQuotedCurrency?: Maybe<SortEnumType>;
  fldActualCost?: Maybe<SortEnumType>;
  fldActualCurrency?: Maybe<SortEnumType>;
  fldPaid?: Maybe<SortEnumType>;
  fldHours?: Maybe<SortEnumType>;
  fldIsCar?: Maybe<SortEnumType>;
  fldLocHierarchy?: Maybe<SortEnumType>;
  dateEntered?: Maybe<SortEnumType>;
  fldParentId?: Maybe<SortEnumType>;
  fldDuration?: Maybe<SortEnumType>;
  fldIsCheckList?: Maybe<SortEnumType>;
  fldCustJob?: Maybe<SortEnumType>;
  fldHtml?: Maybe<SortEnumType>;
  fldSrhkey?: Maybe<SortEnumType>;
  deletedAt?: Maybe<SortEnumType>;
  updatedAt?: Maybe<SortEnumType>;
  deletedBy?: Maybe<SortEnumType>;
  isRecoverable?: Maybe<SortEnumType>;
  autoId?: Maybe<SortEnumType>;
  checks?: Maybe<SortEnumType>;
};
