import { gql, useQuery } from '@apollo/client';
import { Attachment } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { isNotNil } from 'src/utils';

export const GET_DOCUMENT_RELATED_ITEMS = gql`
  query GetDocumentRelatedItems($documentationId: String!) {
    attachmentsForDocumentation(documentationId: $documentationId) {
      __typename
      documentationId
      id
      attachmentType
      docTitle
      fileExtension
      fldAltPath
      fldDescription
      fldFileName
      fldLibType
      fldNotes
      fldPage
      fldRevision
      fldRevisionDate
      fldShowInCentral
      fldSrhkey
      recordId
      recordType
    }
  }
`;

export interface DocumentRelatedItemsResult {
  relatedItems: Attachment[];
  relatedItemsLoading: boolean;
  relatedItemsCount: number;
}

export const useGetDocumenRelatedItems = (documentationId: string): DocumentRelatedItemsResult => {

  if (!isNotNil(documentationId)) {
    return {
      relatedItems: [],
      relatedItemsLoading: false,
      relatedItemsCount: 0
    };
  }

  const { data, loading, error } = useQuery(GET_DOCUMENT_RELATED_ITEMS, {
    variables: { documentationId },
    notifyOnNetworkStatusChange: true,
  });

  if (error) {
    logger('GetDocumentRelatedItems').error(`Error fetching related items for documentation: ${documentationId} -->`, error.message);
  }

  return {
    relatedItems: data?.attachmentsForDocumentation || [],
    relatedItemsLoading: loading,
    relatedItemsCount: data?.attachmentsForDocumentation.length, 
  };
};

