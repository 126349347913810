import { ApolloQueryResult, gql, useQuery } from '@apollo/client';
import { QueryLogEntriesArgs, LogEntryFilterInput, LogEntrySortInput } from 'src/generated/dotnet.graphql';
import { logger } from 'src/helpers/logger';
import { LogEntriesResult } from './useGetLogEntriesWithCounts';

export const GET_LOG_ENTRIES_WITH_NO_COUNTS = gql`
  query GetLogEntriesWithNoCounts(
    $skip: Int
    $take: Int
    $filterInput: LogEntryFilterInput!
    $order: [LogEntrySortInput!]
  ) {
    logEntries(skip: $skip, take: $take, filterInput: $filterInput, order: $order) {
      items {
        __typename
        pkey
        logDate
        maintKey
        eqKey
        fldCost
        curr
        fldCompany
        fldTime
        fldTimeQty
        fldHours
        fldPerformedBy
        fldEnteredBy
        fldTsachk
        department
        fldCrewId
        fldRestricted
        fldIsWarranty
        fldLocHierarchy
        dateEntered
        fldIsCheckList
        fldWorkList
        fldHtml
        fldSrhkey
        fldSms
        checks
        year
        month
        equipment {
          __typename
          id
          countHours
          hoursOffset
          hours
          uniqueName
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
    }
  }
`;

export const useGetLogEntriesWithNoCounts = (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]): LogEntriesResult => {
  const { data, loading, error, refetch } = useQuery(GET_LOG_ENTRIES_WITH_NO_COUNTS, {
    variables: { skip, take, filterInput, order },
    notifyOnNetworkStatusChange: true,
  });

  const loadData = (skip: number, take: number, filterInput: LogEntryFilterInput, order: LogEntrySortInput[]) => {
    logger('GetLogEntriesWithNoCounts').debug('Query variables', { skip: skip, take: take, filterInput: filterInput, order: order });
    refetch({ skip: skip, take: take, filterInput: filterInput, order: order })
      .then((result: ApolloQueryResult<any>) => {
        if (result.data && result.data.logEntries) {
          const { items, pageInfo } = result.data.logEntries;
          logger('GetLogEntriesWithNoCounts').info('Log entries fetched successfully', { recordsCount: items?.length, pageInfo: pageInfo });
        } else {
          logger('GetLogEntriesWithNoCounts').warning('No data received in query result');
        }
      })
      .catch((apolloError) => {
        logger('GetLogEntriesWithNoCounts').error('Error re-fetching log entries -->', apolloError.message);
      });
  };

  if (error) {
    logger('GetLogEntriesWithNoCounts').error('Error fetching log entries -->', error.message);
  }

  return {
    data: loading ? [] : data?.logEntries?.items || [],
    totalCount: data?.logEntries?.totalCount || 0,
    currentCount: data?.logEntries?.items?.length || 0,
    pageInfo: data?.logEntries?.pageInfo,
    loading,
    loadData,
  };
};
